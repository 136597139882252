import { Button, Flex, Icon, Text, Tooltip, useToast } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { BiDownload, BiMedal } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../shared/context/client.context";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import TiempoFormato from "../../../../shared/utils/functions/formatTime";
import { useState } from "react";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import "../../../../ui/scss/medal.scss"
import { CopyTooltip } from "../../../../shared/components/CustomElements/CopyTooltip";

interface Props {
    moduloFUNDAE: boolean;
}

export const InformesColumns = ({ moduloFUNDAE }: Props): ColumnProps[] => {
    const { gestion, contenido } = useUserPolicy();
    const { user } = useAuthContex();
    const navigate = useNavigate();
    const client = useClient();
    const toast = useToast();

    const [diplomaLoading, setDiplomaLoading] = useState<boolean>(false);

    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const validateRequisitos = (progreso: number, puntuacion: number) => {
        if (!progreso || !puntuacion) return false

        if (progreso >= 75 && puntuacion >= 75) return true
        else return false
    }

    const getHoraProcentaje = (tiempoAcceso: number, duracionCurso: number) => {
        if (!tiempoAcceso || !duracionCurso) return 0

        const tiempo = tiempoAcceso / 3600;

        const porcentaje = (tiempo / duracionCurso) * 100;

        return parseInt(porcentaje.toFixed(0)) <= 100 ? parseInt(porcentaje.toFixed(0)) : 100
    }

    const columns: ColumnProps[] = [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                >
                    <CopyTooltip
                        idName={`nombreCopy-${rowData?.id}`}
                        styles={{
                            flex: "1",
                            pl: "5%"
                        }}
                    >
                        <Text
                            id={`nombreCopy-${rowData?.id}`}
                            color="dark_blue"
                            fontSize="14px"
                            fontWeight="400"
                            cursor="copy"
                            whiteSpace={"nowrap"}
                        >
                            {rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                        </Text>
                    </CopyTooltip>

                    <Icon
                        as={BiMedal}
                        color={validateRequisitos(rowData?.progresoLecciones, rowData?.puntuacionMedia)
                            ? "main"
                            : "dark_grey"}
                        className="medal-icon"
                        size="20px"
                    />
                </Flex >
            )
        },
        {
            header: '% act. completadas',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={parseInt(rowData?.progresoLecciones?.toFixed(0)) + " %"}
                    ui={{
                        colorFont: rowData?.progresoLecciones < 75 ? "fail" : "success_main",
                        styles: {
                            margin: "0 auto"
                        }
                    }}
                />
            )
        },
        {
            header: '% ejerc. completados',
            field: 'progresoEntregables',
            key: 'progresoEntregables',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.progresoEntregables?.toFixed(0) + " %"}
                    ui={{
                        colorFont: rowData?.progresoEntregables < 75 ? "fail" : "success_main",
                        styles: {
                            margin: "0 auto"
                        }
                    }}
                />
            )
        },
        {
            header: 'nota media',
            field: 'puntuacionMedia',
            key: 'puntuacionMedia',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.puntuacionMedia?.toFixed(0)}
                    ui={{
                        colorFont: (rowData?.puntuacionMedia || 0) < 75 ? "fail" : "success_main",
                        styles: {
                            margin: "0 auto"
                        }
                    }}
                />
            )
        },
        {
            header: 'tiempo de acceso (horas/%)',
            field: 'tiempoAcceso',
            key: 'tiempoAcceso',
            body: (rowData: any) => (
                <Flex
                    justifyContent="center"
                    color="font"
                    gap="5px"
                    mx="auto"
                >
                    <Text fontWeight="700">
                        <TiempoFormato tiempoAcceso={rowData?.tiempoAcceso} />{' '}
                    </Text>

                    <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="font"
                    >
                        h / {getHoraProcentaje(rowData?.tiempoAcceso, rowData?.grupo?.curso?.duracion)}<Text fontSize="14px" as="span"> %</Text>
                    </Text>


                </Flex>
            )
        },
        {
            header: 'Cuestionario completado',
            field: 'formularioCompletado',
            key: 'formularioCompletado',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.formularioCompletado ? "Si" : "No"}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase",
                            margin: "0 auto"
                        }
                    }}
                />
            )
        },
        {
            header: 'Descarga diploma',
            field: 'diplomaDescargado',
            key: 'diplomaDescargado',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.diplomaDescargado ? "Si" : "No"}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase",
                            margin: "0 auto"
                        }
                    }}
                />
            )
        },
        {
            header: 'Pend. de corrección',
            field: 'meta.entregablesPendientes',
            key: 'meta.entregablesPendientes',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.meta?.entregablesPendientes}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase",
                            margin: "0 auto"
                        }
                    }}
                />
            )
        },
        {
            header: '',
            field: user?.role?.nombre !== UserRolEnum.SUPERVISOR ? 'diploma' : 'resumen',
            key: user?.role?.nombre !== UserRolEnum.SUPERVISOR ? 'diploma' : 'resumen',
            body: (rowData: any) => user?.role?.nombre !== UserRolEnum.SUPERVISOR ? (
                <Button
                    mr="16px"
                    isDisabled={false} // || !rowData?.diplomaDescargado}
                    isLoading={diplomaLoading}
                    h="auto"
                    p="7px 12px"
                    bg="secondary"
                    color="white"
                    fontSize="12px"
                    fontWeight="600px"
                    rightIcon={<Icon as={BiDownload} boxSize="20px" />}
                    _hover={{ bg: "variant", color: "black" }}
                    onClick={async () => {
                        // setDiplomaLoading(true)

                        // const grupo = rowData?.grupoId

                        // getInformes({
                        //     data: {
                        //         grupoId: grupo,
                        //         matriculas: [
                        //             {
                        //                 id: rowData?.id,
                        //                 informes: ["diploma"],
                        //                 debug: true
                        //             }
                        //         ]
                        //     },
                        //     client: client
                        // })
                        //     .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "El diploma se está generando, recibirá una notificación con el enlace de descarga"))
                        //     .catch((error) => {
                        //         const errors = handleErrors(
                        //             error?.response?.data?.errors,
                        //             error?.response?.status
                        //         )

                        //         errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                        //     })
                        //     .finally(() => setDiplomaLoading(false))
                    }}
                >
                    Diploma
                </Button>
            )
                :
                (
                    <Tooltip
                        label="Ver resumen completo del alumno"
                        hasArrow
                    >
                        <Button
                            mr="16px"
                            h="auto"
                            p="7px 12px"
                            bg="secondary"
                            color="white"
                            fontSize="12px"
                            fontWeight="600px"
                            _hover={{ bg: "variant", color: "black" }}
                            onClick={() => navigate(`/matriculas/matriculas/${rowData?.id}/resumen`)}
                        >
                            Ver resumen
                        </Button>
                    </Tooltip>
                )
        }
    ];

    const columnsInformes: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            selectionMode: "multiple",
        },
    ]

    return moduloFUNDAE ? [...columnsInformes, ...columns] : columns
}