import { Button, Flex, IconButton, Link, Skeleton, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { MJMLtoHTML } from "../../../shared/middlewares/plantillas.middleware";
import { useClient } from "../../../shared/context/client.context";
import { Link as LinkRouter } from "react-router-dom";
import { IoDuplicateOutline, IoTrashOutline } from "react-icons/io5";

interface Props {
    plantilla: {
        contenido: string;
        createdAt: string;
        id: string;
        nombre: string;
        tipo: string;
        updatedAt: string;
        categoria: string;
    }
    loading: boolean;
    error: {
        response: {
            status: number;
            data: {
                errors: any[];
            };
        };
    } | null;
    handleDuplicate?: (plantilla: any) => void;
    handleDelete?: (plantilla: any) => void;
}

export const CardPlantilla = ({
    plantilla,
    loading,
    error,
    handleDuplicate,
    handleDelete
}: Props) => {
    const client = useClient();
    const [preview, setPreview] = useState<string>()

    useEffect(() => {
        if (!loading && !error && plantilla) {
            if (plantilla?.tipo === "html") return setPreview(plantilla?.contenido)

            MJMLtoHTML({
                mjml: plantilla?.contenido,
                client: client
            })
                .then((response) => {
                    const html = response?.data?.html.replace("<!--[if mso]>", scrollBar);

                    const modifiedHtml = `
                        <style>
                            body {
                                margin: 0;
                                padding: 0;
                                overflow: hidden;
                                transform: translate(0px, -315px);
                                transform-origin: top left;
                            }
                        </style>
                        ${html}
                    `;

                    setPreview(modifiedHtml);
                })
                .catch((error) => console.log(error))
        }
    }, [plantilla, loading, error, client]);

    return (
        loading
            ?
            <Skeleton
                minW="420px"
                h="330px"
                rounded="10px"
            />
            :
            <Flex
                direction="column"
                gap="20px"
                pos="relative"
                zIndex={"10"}
            >
                <Flex
                    rounded="20px"
                    minW="20px"
                    h="330px"
                    bg="#E7E7E7"
                    px="20px"
                    pt="20px"
                    overflow={"hidden"}
                >
                    <iframe
                        title="Email Preview"
                        srcDoc={preview}
                        width="100%"
                        height="330px"
                        style={{
                            transform: "scale(1)",
                            transformOrigin: "top left",
                            overflow: "hidden",
                            borderRadius: "20px",
                        }}
                    />

                </Flex>

                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        fontSize="18px"
                        fontWeight="500"
                        color="pureblack"
                        textTransform="capitalize"
                        ml="10px"
                    >
                        {plantilla?.nombre}
                    </Text>

                    {plantilla?.categoria === "diploma" &&
                        <Flex
                            gap="10px"
                        >
                            <Button
                                leftIcon={
                                    <IoDuplicateOutline
                                        size="14px"
                                    />
                                }
                                fontSize="12px"
                                p="9px"
                                onClick={() => handleDuplicate && handleDuplicate(plantilla)}
                                zIndex={"21"}
                            >
                                Duplicar
                            </Button>

                            <IconButton
                                icon={
                                    <IoTrashOutline
                                        size="16px"
                                    />
                                }
                                aria-label="Eliminar"
                                onClick={() => {
                                    handleDelete && handleDelete(plantilla)
                                }}
                                bg="transparent"
                                border="1px solid"
                                borderColor="border"
                                zIndex={"21"}
                            />
                        </Flex>
                    }
                </Flex>

                <Link
                    zIndex={"20"}
                    as={LinkRouter}
                    pos={"absolute"}
                    bottom="0"
                    left="0"
                    right="0"
                    top="0"
                    rounded={"10px"}
                    to={`${plantilla?.id}`}
                />
            </Flex>
    )
}

const scrollBar = `<style>
::-webkit-scrollbar {
    width: 0;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
}
</style>`