import { Badge, Box, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react"
import { MatriculasInt } from "../../../../../../interfaces/MatriculasInt"
import { InformationBox } from "../../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { BiDownload, BiLock, BiPencil } from "react-icons/bi";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { InformationDateInput } from "@imaginagroup/bit-components.ui.elements.information-date-input"
import MatriculasPerfilSkeleton from "../../../../components/MatriculasPerfilSkeleton";
import { recordatorioDiplomaEmail, resendEmail } from "../../../../../../shared/middlewares/token.middleware";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../../../shared/utils/functions/handleErrors";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { ClientInt } from "../../../../../../shared/services/http.service";
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { updateAsignacion } from "../../../../../../shared/middlewares/facturacion.middlewares";
import { updateMatricula } from "../../../../../../shared/middlewares/matriculas.middlewate";
import { useUserPolicy } from "../../../../../../shared/hooks/PermissionPolicy";
import { CustomButton } from "../../../../../../shared/components/CustomElements/CustomButton";
import { useModulos } from "../../../../../../shared/hooks/ModuloFundae";
import { InformationSelect } from "@imaginagroup/bit-components.ui.elements.information-select"
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../../shared/hooks/useData";
import { EmpresasInt } from "../../../../../../interfaces/EmpresasInt";
import { informationSelectStyles } from "../../../../../../ui/informationSelectStyles";
import { DateTime } from "luxon";
import { formatTimezone, parseDate } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { InformationPhoneInput } from "../../../../../../shared/components/Information/InformationPhoneInput";

interface Props {
    matricula: MatriculasInt;
    loading: boolean;
    client: ClientInt;
    Refresh: () => void;
}

export const TabPerfilMatricula = ({ matricula, loading, client, Refresh }: Props) => {
    const timeZone = useTimeZone();
    const { gestion, contenido } = useUserPolicy();
    const { moduloFUNDAE } = useModulos();
    const { user } = useAuthContex();
    const toast = useToast();
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const updateValue = async (value: any) => {
        if (!matricula?.id) return;

        if ("fechaInicio" in value) {
            let fechaInicio = parseDate(value.fechaInicio) + "T12:00"
            fechaInicio = DateTime.fromISO(fechaInicio).setZone(timeZone).startOf('day').toString()

            value.fechaInicio = fechaInicio
        }

        if ("fechaFin" in value) {
            let fechaFin = parseDate(value.fechaFin) + "T12:00"
            fechaFin = DateTime.fromISO(fechaFin).setZone(timeZone).endOf('day').toString()

            value.fechaFin = fechaFin
        }

        updateMatricula({
            id: matricula?.id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh();
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente");
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const updateImporte = async (value: any) => {
        updateAsignacion({
            id: matricula?.asignacion?.id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh();
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Importe del tutor actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const handleResendCredentials = async () => {
        if (!matricula?.id || !matricula?.user?.id) return;

        resendEmail({
            client: client,
            user: matricula?.user?.id,
            matricula: matricula?.id,
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Credenciales reenviadas correctamente"))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const updateEmpresaFactura = async (value: any) => {
        if (!matricula?.id) return toastNotify(toast, StatusEnumTypes.ERROR, "No se ha podido actualizar la empresa factura");

        return Promise.all([
            updateMatricula({
                id: matricula?.id,
                data: value,
                client: client
            }),
            updateAsignacion({
                id: matricula?.asignacion?.id,
                data: value,
                client: client,
                ignoreRequest: !matricula?.asignacion
            })
        ])
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Empresa factura actualizada correctamente")
            })
    }

    const handleResendDiploma = async () => {
        if (!matricula?.id) return;

        recordatorioDiplomaEmail({
            client: client,
            matriculaId: matricula?.id,
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Recordatorio de descarga diploma enviado correctamente"))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        loading ?
            <MatriculasPerfilSkeleton
                moduloFUNDAE={moduloFUNDAE}
                isSupervisor={isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? true : false}
            />
            :
            <Flex gap="20px">
                <Flex direction="column" flex="1">

                    <InformationBox
                        title="Datos de la matrícula"
                        component={
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Estado:
                                </Text>


                                <Badge
                                    color={
                                        matricula?.estado == "2" ? "font" :
                                            matricula?.estado == "1" ? "#EEEDFA"
                                                : "success_main"
                                    }
                                    bg={
                                        matricula?.estado == "2" ? "light_grey" :
                                            matricula?.estado == "1" ? "secondary"
                                                : "success_bg"
                                    }
                                    borderColor={
                                        matricula?.estado == "2" ? "light_grey" :
                                            matricula?.estado == "1" ? "secondary"
                                                : "success_variant"
                                    }
                                    fontSize="10px"
                                    fontWeight="500"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                    borderRadius="14px"
                                    borderWidth="1px"
                                    p="5px 8px"
                                >
                                    {
                                        matricula?.estado == "2" ? "Inactivo" :
                                            matricula?.estado == "1" ? "Próximo"
                                                : "Activo"
                                    }
                                </Badge>
                            </Flex>
                        }
                    >

                        <Box>
                            {(!matricula?.heredaFecha) &&
                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    p="10px 20px"
                                    alignItems="center"
                                    gap="10px"
                                    w="100%"
                                >
                                    <Text
                                        color="fail"
                                    >
                                        Las fechas son distintas al grupo
                                    </Text>
                                </Flex>
                            }

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationDateInput
                                    locale="es"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="fechaInicio"
                                    label="Fecha Inicio"
                                    updateValue={updateValue}
                                    ui={{
                                        labelStyles: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    defaultValue={
                                        formatTimezone({
                                            date: matricula?.fechaInicio as string,
                                            timeZone
                                        })
                                    }
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom={isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? "1px solid" : "0px"}
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationDateInput
                                    locale="es"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="fechaFin"
                                    label="Fecha Fin"
                                    updateValue={updateValue}
                                    ui={{
                                        labelStyles: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    defaultValue={
                                        formatTimezone({
                                            date: matricula?.fechaFin as string,
                                            timeZone
                                        })
                                    }
                                    isDisabled={(isRoleAllowed([UserRolEnum.SUPERVISOR], user?.role?.nombre)) ? true : false}
                                />

                                <Flex boxSize="20px">
                                    {(isRoleAllowed([UserRolEnum.SUPERVISOR], user?.role?.nombre)) ?
                                        < Icon as={BiLock} boxSize="20px" />
                                        :
                                        <Icon as={BiPencil} boxSize="20px" />
                                    }
                                </Flex>
                            </Flex>

                            {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                                <>
                                    <Flex
                                        borderBottom={!matricula?.fechaDescargaDiploma ? "1px solid" : "none"}
                                        borderColor="light_grey"
                                        p="10px 20px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap="10px"
                                    >
                                        <InformationInput
                                            onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                            name="importe"
                                            label="Importe matrícula"
                                            updateValue={updateValue}
                                            defaultValue={
                                                matricula?.importe
                                            }
                                            isPrice={true}
                                            isDisabled={contenido || matricula?.asignacion && matricula?.asignacion?.liquidada}
                                        />

                                        <Flex boxSize="20px">
                                            {matricula?.asignacion && matricula?.asignacion?.liquidada ?
                                                <Icon as={BiLock} boxSize="20px" />
                                                :
                                                <Icon as={BiPencil} boxSize="20px" />
                                            }
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        p="10px 20px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap="10px"
                                        display={UserRolEnum.ADMIN === user?.role?.nombre ? "flex" : "none"}
                                    >
                                        <InformationInput
                                            onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                            name="importe"
                                            label="Importe tutor"
                                            updateValue={updateImporte}
                                            defaultValue={
                                                matricula?.asignacion?.importe
                                            }
                                            isDisabled={true}
                                            isPrice={true}
                                        />

                                        <Flex boxSize="20px">
                                            <Icon as={BiLock} boxSize="20px" />
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        borderBottom={matricula?.fechaDescargaDiploma ? "1px solid" : "none"}
                                        borderColor="light_grey"
                                        p="10px 20px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap="10px"
                                    >
                                        <InformationSelect
                                            name="empresaFacturaId"
                                            label="Empresa factura"
                                            updateValue={updateEmpresaFactura}
                                            options={
                                                (empresas === undefined || empresas === null)
                                                    ? []
                                                    : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                            }
                                            defaultValue={{ value: matricula?.empresaFacturaId, label: matricula?.empresaFactura?.nombre }}
                                            isDisabled={matricula?.asignacion?.liquidada}
                                            ui={{
                                                informationSelectStyles: informationSelectStyles,
                                                styleLabel: {
                                                    textTransform: "uppercase",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    letterSpacing: "0.24px"
                                                }
                                            }}
                                        />

                                        <Flex boxSize="20px">
                                            <Icon as={matricula?.asignacion?.liquidada ? BiLock : BiPencil} boxSize="20px" />
                                        </Flex>
                                    </Flex>
                                </>

                            }

                            {matricula?.fechaDescargaDiploma ?
                                <>
                                    <Flex
                                        p="20px"
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Fecha de descarga
                                        </Text>

                                        <Flex gap="3px" flex="1" ml="15px">
                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="400"
                                            >
                                                {matricula?.fechaDescargaDiploma && formatTimezone({ date: matricula?.fechaDescargaDiploma as string, timeZone })}
                                            </Text>
                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        p="20px"
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Diploma
                                        </Text>

                                        <Flex gap="3px" flex="1" ml="15px">
                                            <Button
                                                ml="auto"
                                                color="purewhite"
                                                fontSize="14px"
                                                fontWeight="400"
                                                bg="black"
                                                p="7px 15px"
                                                h="fit-content"
                                                leftIcon={<Icon as={BiDownload} />}
                                                _hover={{ color: "black", bg: "grey" }}
                                                isDisabled={!matricula?.diplomaDescargado}
                                                onClick={() => window.open(matricula?.urlDiploma, '_blank')}
                                            >
                                                Descargar
                                            </Button>
                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>
                                </>
                                :

                                null
                            }
                        </Box>
                    </InformationBox>


                    <InformationBox
                        title="Datos del grupo"
                        component={
                            moduloFUNDAE && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Text
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        FUNDAE:
                                    </Text>

                                    <Badge
                                        color={
                                            matricula?.grupo?.fundae === false ? "font" : "success_main"
                                        }
                                        bg={
                                            matricula?.grupo?.fundae === false ? "light_grey" : "success_bg"
                                        }
                                        borderColor={
                                            matricula?.grupo?.fundae === false ? "light_grey" : "success_variant"
                                        }
                                        borderWidth="1px"
                                        fontSize="10px"
                                        fontWeight="500"
                                        letterSpacing="0.2px"
                                        textTransform="uppercase"
                                        borderRadius="14px"
                                        p="5px 10px"
                                    >
                                        {
                                            matricula?.grupo?.fundae ? "Si" : "No"
                                        }
                                    </Badge>
                                </Flex>
                                : undefined
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Denominación del curso"
                                    defaultValue={matricula?.grupo?.curso?.nombre ? matricula?.grupo?.curso?.nombre : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationDateInput
                                    locale="es"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="fechaInicio"
                                    label="Fecha Inicio"
                                    updateValue={updateValue}
                                    ui={{
                                        labelStyles: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    defaultValue={
                                        formatTimezone({
                                            date: matricula?.grupo?.fechaInicio,
                                            timeZone
                                        })
                                    }
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationDateInput
                                    locale="es"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="fechaFin"
                                    label="Fecha Fin"
                                    updateValue={updateValue}
                                    ui={{
                                        labelStyles: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    defaultValue={
                                        formatTimezone({
                                            date: matricula?.grupo?.fechaFin,
                                            timeZone
                                        })
                                    }
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="duracion"
                                    label="horas"
                                    defaultValue={matricula?.grupo?.curso?.duracion ? matricula?.grupo?.curso?.duracion : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Datos del alumno"
                        component={
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Estado:
                                </Text>

                                <Badge
                                    fontSize="10px"
                                    fontWeight="500"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                    borderRadius="14px"
                                    borderWidth="1px"
                                    p="5px 8px"
                                    color={
                                        matricula?.user?.activo === false ? "font" : "success_main"
                                    }
                                    bg={
                                        matricula?.user?.activo === false ? "light_grey" : "success_bg"
                                    }
                                    borderColor={
                                        matricula?.user?.activo === false ? "light_grey" : "success_variant"
                                    }
                                >
                                    {
                                        matricula?.user?.activo ? "Activo" : "Inactivo"
                                    }
                                </Badge>
                            </Flex>
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={matricula?.user?.nombre ? matricula?.user?.nombre : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="apellidos"
                                    label="Apellidos"
                                    defaultValue={matricula?.user?.apellidos ? matricula?.user?.apellidos : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="dni"
                                    label="documento identidad"
                                    validatorDNI={true}
                                    isInvalidMessage="El DNI ingresado no es correcto"
                                    defaultValue={matricula?.user?.dni ? matricula?.user?.dni : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                gap="10px"
                            >
                                <InformationPhoneInput
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={matricula?.user?.telefono ? matricula?.user?.telefono : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                    notValidNumber={() => toastNotify(toast, StatusEnumTypes.ERROR, "El numero no tiene un formato valido.")}
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom={isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? "1px" : "0px"}
                                borderColor="light_grey"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    type="email"
                                    defaultValue={matricula?.user?.email ? matricula?.user?.email : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                                matricula?.grupo?.fundae &&
                                !matricula?.diplomaDescargado &&
                                (matricula?.progresoLecciones || 0) >= 75 &&
                                (matricula?.progresoEntregables || 0) >= 75 &&
                                (matricula?.puntuacionMedia || 0) >= 75 &&
                                <Flex
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Text
                                        flex="1"
                                        color="gray"
                                        fontSize="15px"
                                        fontWeight="500"
                                        letterSpacing="0.24px"
                                        textTransform="capitalize"
                                    >
                                        Recordatorio de diploma
                                    </Text>

                                    <Flex
                                        pr="5%"
                                    >
                                        <CustomButton
                                            button={{
                                                label: "Enviar",
                                                onClick: handleResendDiploma,
                                                isDisabled: contenido,
                                            }}
                                            tooltip={{
                                                label: contenido ? "No tienes permisos para esta acción" : "Enviar recordatorio de descarga diploma",
                                                isDisabled: false,
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            }

                            {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                                <Flex
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Text
                                        flex="1"
                                        color="gray"
                                        fontSize="15px"
                                        fontWeight="500"
                                        letterSpacing="0.24px"
                                        textTransform="capitalize"
                                    >
                                        Credenciales matrícula
                                    </Text>

                                    <Flex
                                        pr="5%"
                                    >
                                        <CustomButton
                                            button={{
                                                label: "Reenviar",
                                                onClick: handleResendCredentials,
                                                isDisabled: contenido,
                                            }}
                                            tooltip={{
                                                label: contenido ? "No tienes permisos para esta acción" : "Reenviar credenciales del alumno",
                                                isDisabled: false,
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            }
                        </Box>
                    </InformationBox>

                    {(matricula?.grupo?.fundae && moduloFUNDAE) &&
                        (!isRoleAllowed([UserRolEnum.SUPERVISOR], user?.role?.nombre)) &&

                        <Flex direction="column" flex="1" gap="20px">
                            <InformationBox
                                title="Requisitos FUNDAE"
                            >
                                <Box>
                                    <Flex
                                        p="20px 20px"
                                        alignItems="center"
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Actividades completadas
                                        </Text>

                                        <Flex gap="3px" flex="1" ml="15px">
                                            <Text
                                                color={(matricula?.progresoLecciones || 0) < 75 ? "fail" : "success"}
                                                fontSize="14px"
                                                fontWeight="600"
                                            >
                                                {matricula?.progresoLecciones?.toFixed(0)}
                                            </Text>

                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="500"
                                            >
                                                %
                                            </Text>
                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        p="20px 20px"
                                        alignItems="center"
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Ejercicios Completados
                                        </Text>

                                        <Flex gap="3px" flex="1" ml="15px">
                                            <Text
                                                color={(matricula?.progresoEntregables || 0) < 75 ? "fail" : "font"}
                                                fontSize="14px"
                                                fontWeight="600"
                                            >
                                                {matricula?.progresoEntregables?.toFixed(0)}
                                            </Text>

                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="500"
                                            >
                                                %
                                            </Text>
                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        p="20px 20px"
                                        alignItems="center"
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Nota media
                                        </Text>

                                        <Flex
                                            gap="3px"
                                            flex="1"
                                            ml="15px"
                                            align="center">
                                            <Text
                                                color={(matricula?.puntuacionMedia || 0) < 75 ? "fail" : "success"}
                                                fontSize="14px"
                                                fontWeight="600"
                                            >
                                                {matricula?.puntuacionMedia!.toFixed(0)}
                                            </Text>

                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="500"
                                            >
                                                / 100
                                            </Text>
                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        p="20px 20px"
                                        alignItems="center"
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Formulario completado
                                        </Text>

                                        <Flex gap="3px" flex="1" ml="15px">

                                            {matricula?.formularioCompletado === false ?
                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                >
                                                    NO
                                                </Text>
                                                :
                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                >
                                                    SI
                                                </Text>}

                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        p="20px 20px"
                                        alignItems="center"
                                        borderBottom="1px solid"
                                        borderColor="light_grey"
                                        gap="10px"
                                    >
                                        <Text
                                            flex="1"
                                            color="gray"
                                            fontSize="15px"
                                            fontWeight="500"
                                            letterSpacing="0.24px"
                                            textTransform="capitalize"
                                        >
                                            Diploma descargado
                                        </Text>

                                        <Flex gap="3px" flex="1" ml="15px">

                                            {matricula?.diplomaDescargado === false ?
                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                >
                                                    NO
                                                </Text>
                                                :
                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                >
                                                    SI
                                                </Text>}

                                        </Flex>

                                        <Flex boxSize="20px">
                                        </Flex>
                                    </Flex>
                                </Box>
                            </InformationBox>
                        </Flex>
                    }
                </Flex>
            </Flex >
    )
}