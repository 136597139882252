import { getData } from "../../middlewares/middlewares";
import { ClientInt } from "../../services/http.service";
import tenantInstance from "../../services/tenantInstance.service";
import { AuditsTipoEnum } from "../Types/AuditsTypes";
import { EndpointTypes } from "../Types/EndpointTypes";
import { EntregableEstadoEnum } from "../Types/EntregableEstadoEnum";
import { FeedbackTypeEnum } from "../Types/FeedbackTypeEnum";
import { FiltrosNames } from "../Types/FiltrosEnum";
import { capitalizeFirst } from "./capitalizeFirst";

export const updateFilterInput = (
    prevFilter: { name: string; value: string; label: string; }[],
    name: string,
    value: string,
) => {
    if (value === "") return prevFilter.filter((filter: any) => filter.name !== name);

    const filterIndex = prevFilter.findIndex((filter) => filter.name === name);

    if (filterIndex !== -1) {
        const updatedFilter = {
            ...prevFilter[filterIndex],
            value,
            label: value
        };

        return [
            ...prevFilter.slice(0, filterIndex),
            updatedFilter,
            ...prevFilter.slice(filterIndex + 1)
        ];
    } else {
        return [
            ...prevFilter,
            { name, value, label: value }
        ];
    }
}

export const updateFilters = (
    prevFilter: { name: string; value: string; label: string; }[],
    data: { name: string; value: string; label: string; },
) => {
    const filterIndex = prevFilter.findIndex((filter) => filter?.name === data?.name);

    if (filterIndex !== -1) {
        prevFilter[filterIndex] = data;

        return [...prevFilter];
    } else {
        return [
            ...prevFilter,
            data
        ];
    }
}

export const updateMultiFilters = (
    prevFilter: Array<{ name: string; value: string; label: string; } | { name: string; data: { value: string; label: string; }[] }>,
    data: {
        name: string;
        data: { value: string; label: string; }[];
    },
) => {
    const filterIndex = prevFilter.findIndex((filter) => filter?.name === data?.name);

    if (filterIndex !== -1) {
        if (data?.data?.length === 0)
            prevFilter.splice(filterIndex, 1)
        else
            prevFilter[filterIndex] = data;

        return [...prevFilter];
    } else {
        return [
            ...prevFilter,
            data
        ];
    }
}

export const getFiltersData = (
    name: string,
    selects: Array<{ name: string; value: string; label: string; } | { name: string; data: { value: string; label: string; }[] }>,
    isMulti?: boolean
): { value: string; label: string; } | { value: string; label: string; }[] => {
    const filterInput = selects.find((select: any) => select?.name === name);

    if (filterInput && isMulti) {
        return [...(filterInput as { name: string; data: { value: string; label: string; }[] })?.data]
    }

    if (filterInput)
        return {
            value: (filterInput as { name: string; value: string; label: string; })?.value,
            label: (filterInput as { name: string; value: string; label: string; })?.label
        }

    return isMulti ? [] : { value: "", label: "Todos" };
};

export const getFiltro = (filtros: {
    name: string;
    value: string;
    label: string;
    data?: any[];
}[]) => {
    let options = {};

    filtros?.map(({ name, value, data }) => {
        if (data !== undefined && data.length > 0) {
            const ids: string[] = [...data?.map((f: { name: string; value: string; label: string; }) => f?.value)]

            if (ids.length > 0)
                options = {
                    ...options,
                    [name]: ids?.join(",")
                }
        } else {
            if (value !== "")
                options = {
                    ...options,
                    [name]: value
                }
        }
    })

    return options;
}

export const getFiltrosActivos = (
    filtros: { name: string; value: string; label: string; data?: any[] }[],
    query: Record<string, string | string[] | number>,
    setFiltros: (filtro: any) => void,
    setQuery: (query: any) => void,
    clearAndReset: () => void,
) => {
    const filters: { key: string; nombre: string; clear: () => void; }[] = []

    filtros?.forEach(({ name, label, data }) => {
        if (name === "page" || name === "limit" || name === "sortBy" || name === "order" || name === "tab" || name === "subTab") return
        const existingFilterIndex = filters.findIndex(filtro => filtro.key === name);

        const filtro = {
            key: name,
            nombre: data !== undefined ? capitalizeFirst(name) : label,
            clear: () => {
                clearAndReset();
                const cleanSelects = filtros?.filter(s => s?.name !== name)
                setFiltros(cleanSelects)
                const newQuery = Object.fromEntries(Object.entries(query).filter(q => q[0] !== name))
                setQuery({ ...newQuery })
            },
        }

        if (existingFilterIndex !== -1) filters[existingFilterIndex] = filtro
        else filters.push(filtro)
    })

    return filters;
}

export const getInitialFilter = (
    query: Record<string, string | string[] | number>,
    filtros: { name: string; value: string; label: string; }[],
    setFiltros: (filtro: any) => void,
    client: ClientInt,
    filterElements: any[],
) => {
    Object.entries(query).forEach(([key, value]) => {
        if (filterElements?.find((filter) => filter?.name === key && filter.multi === true)) {
            const data: { value: string; label: string; }[] = [];

            if (filtros.find((filtro) => filtro?.name === key)) return

            else
                (value?.toString())?.split(",")?.map((v: string) => {
                    setLabel(key, v, client).then((response: any) => {
                        data.push({
                            value: (v)?.toString(),
                            label: response,
                        })

                        setFiltros(
                            (prev: { name: string; value: string; label: string; }[]) => [
                                ...prev,
                                {
                                    name: key,
                                    data: data,
                                }
                            ]
                        );
                    })
                })
        } else {
            if (filtros.find((filtro) => filtro?.name === key)) return

            setLabel(key, value as string, client).then((response: any) => {
                setFiltros(
                    (prev: { name: string; value: string; label: string; }[]) => [
                        ...prev,
                        {
                            name: key,
                            value: value as string,
                            label: response,
                        }
                    ]
                );
            })
        }
    })
}

export const setLabel = async (key: string, value: string, client: ClientInt) => {
    const tenant = await tenantInstance();

    switch (key) {
        /*
            *** Get data from Types ***
        */
        case FiltrosNames.INF_PREGRESO:
            return "Progreso inf. " + value + "%"
        case FiltrosNames.ESTADO_GRUPO:
            return value === "0" ? "Activo" : value === "1" ? "Próximo" : value === "2" ? "Inactivo" : "Estado grupo";
        case FiltrosNames.FUNDAE:
            return value === "true" ? "FUNDAE" : "No FUNDAE";
        case FiltrosNames.MATRICULA_DE_BAJA:
            return value === "true" ? "Matriculas de baja" : "Matriculas de alta";
        case FiltrosNames.SEMANAS:
            return value == "1" ? "1 Semana" : value == "2" ? "2 Semanas" : value == "3" ? "3 Semanas" : "4 Semanas";
        case FiltrosNames.CONTRATACION:
            return value === "true" ? "Colaborador" : "Contratado";
        case FiltrosNames.ESTADO_LIQUIDACION:
            return value === "true" ? "Pagada" : "No Pagada";
        case FiltrosNames.ESTADO_ENTREGABLE:
            return (
                value === EntregableEstadoEnum.CORRECTO ? "Incorrecto" :
                    value === EntregableEstadoEnum.ERROR ? "Correcto"
                        : "Pendiente"
            );
        case FiltrosNames.ESTADO_USUARIO:
            return value === "true" ? "Activos" : "Inactivos";
        case FiltrosNames.CORREOS_ACTIVOS:
            return value === "true" ? "Correos Activos" : "Correos Inactivos";
        case FiltrosNames.ESTADO_MATRICULA:
            return value === "true" ? "Activas" : "Inactivas";
        case FiltrosNames.DESVIACION:
            return value === "true" ? "Con desviacion" : "Sin desviacion";
        case FiltrosNames.POR_CONFIGURAR:
            return value === "true" ? "Por Configurar" : "Configurados";
        case FiltrosNames.SIN_ACCEDER:
            return value === "true" ? "Sin acceder" : "Con acceso";
        case FiltrosNames.TIPO:
            return value == FeedbackTypeEnum.CURSO ? "Curso"
                : value == FeedbackTypeEnum.MODULO ? "Modulo"
                    : value == FeedbackTypeEnum.LECCION ? "Lección"
                        : value == FeedbackTypeEnum.TUTOR ? "Tutor"
                            : value == AuditsTipoEnum.CURSO ? "Curso"
                                : value == AuditsTipoEnum.ASIGNACION ? "Asignación"
                                    : value == AuditsTipoEnum.CAMBIO_DATO ? "Cambio de dato"
                                        : value == AuditsTipoEnum.EMPRESA ? "Empresa"
                                            : value == AuditsTipoEnum.EXAMEN ? "Examen"
                                                : value == AuditsTipoEnum.GRUPO ? "Grupo"
                                                    : value == AuditsTipoEnum.LECCION ? "Lección"
                                                        : value == AuditsTipoEnum.LIQUIDACION ? "Liquidación"
                                                            : value == AuditsTipoEnum.MATRICULA ? "Matrícula"
                                                                : value == AuditsTipoEnum.MODULO ? "Módulo"
                                                                    : value == AuditsTipoEnum.SUPERVISOR ? "Supervisor"
                                                                        : value == AuditsTipoEnum.TEMA ? "Tema"
                                                                            : value == AuditsTipoEnum.USER ? "Usuario"
                                                                                : "Tipo";

        /*
            *** Get data from API ***
        */
        case FiltrosNames.CURSO:
            const { data: curso } = await getData(tenant + "/" + client + EndpointTypes.CURSOS + "/" + value)
            return curso?.data?.nombre;
        case FiltrosNames.EMPRESA:
        case FiltrosNames.EMPRESA_ID:
        case FiltrosNames.ASIGNACION_EMPRESA:
        case FiltrosNames.LIQUIDACION_EMPRESA:
            const { data: empresa } = await getData(tenant + "/" + client + EndpointTypes.EMPRESA + "/" + value)
            return empresa?.data?.nombre;
        case FiltrosNames.EMPRESA_ORGANIZADORA:
            const { data: empresaInerna } = await getData(tenant + "/" + client + EndpointTypes.EMPRESA + "/" + value)
            return empresaInerna?.data?.nombre;
        case FiltrosNames.GRUPO:
        case FiltrosNames.GRUPO_ID:
            const { data: grupo } = await getData(tenant + "/" + client + EndpointTypes.GRUPOS + "/" + value)
            return grupo?.data?.nombre;
        case FiltrosNames.TUTOR:
            const { data: tutor } = await getData(tenant + "/" + client + EndpointTypes.USUARIOS + "/" + value)
            return tutor?.data?.nombre + " " + tutor?.data?.apellidos;
        case FiltrosNames.EXAMEN:
            const { data: examen } = await getData(tenant + "/" + client + EndpointTypes.EXAMENES + "/" + value)
            return examen?.data?.nombre;
        default:
            return value;
    }
}
