import { Button, Flex, FormLabel, Grid, Icon, ListItem, Text, UnorderedList, useDisclosure, useToast } from "@chakra-ui/react"
import { CursosInt, LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { HiOutlineClipboard, HiOutlineDocumentDownload, HiOutlineDocumentText, HiOutlineLink, HiOutlinePaperClip, HiOutlinePlay } from "react-icons/hi";
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useClient } from "../../../../shared/context/client.context";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { addLeccion, loadContenido, updateLeccion } from "../../../../shared/middlewares/lecciones.middleware";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { LeccionTipoEnum, SubtipoLeccion } from "../../../../shared/utils/Types/LeccionTipoEnum";
import { InformationComponent } from "../../../../shared/components/CustomElements/InformationComponent";
import { BiInfoCircle } from "react-icons/bi";
import DropzoneComponent from "../../../../shared/components/Dropzone/Dropzone";
import { FormTextEditor } from "../../../../shared/components/FormElements/FormTextEditor";
import { FiEdit3 } from "react-icons/fi";
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo";
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input";
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { FormNewTimeInput } from "../../../../shared/components/FormElements/FormNewTimeInput";
import { PiExam } from "react-icons/pi";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { useModulos } from "../../../../shared/hooks/ModuloFundae";

export enum StepTypes {
    SELECT_TIPO = "select_tipo",
    SELECT_SUBTIPO = "select_subtipo",
    SELECT_CONTENT = "select_content",
}

interface TipoContenido {
    tipo: LeccionTipoEnum,
    icon: any,
    titulo: string,
    descripcion: string
    subtipo: SubtipoLeccion | null;
    isAllowed: boolean;
}

interface Props {
    curso: CursosInt | undefined;
    data: Partial<LeccionInt> | undefined;
    setRefreshLeccion: Dispatch<SetStateAction<boolean>>;
    setRefreshModulos: () => void;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
}

export const AddLeccion = ({ data, setRefreshLeccion, setRefreshModulos, setSelected, curso }: Props) => {
    const { moduloAI } = useModulos();
    const [step, setStep] = useState<StepTypes>(StepTypes.SELECT_TIPO);
    const [file, setFile] = useState<File | null>(null);
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editName, setEditName] = useState<boolean>(false);
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });
    const toast = useToast();
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();

    useEffect(() => {
        if (editName) {
            const inputElement: HTMLInputElement | null = document.querySelector('input[name="nombre"]');

            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [editName])

    const initialValues: any = {
        moduloId: data?.moduloId,
        nombre: data?.nombre,
        publicado: data?.publicado,
        orden: data?.orden,
        tipo: null,
        contenido: data?.contenido,
        descripcion: data?.descripcion,
        subtipo: null,
        sesionVivo: {
            fechaInicio: null,
            fechaFin: null,
            online: true,
            ubicacion: null
        },
        AIConfig: {
            tipo: null,
            correccion: null,
            pregunta: null,
            puntuacion: null
        }
    };

    const validationSchema = Yup.object().shape({
        tipo: Yup.string()
            .required('¡El tipo de lección es obligatorio!'),
        nombre: Yup.string()
            .required('¡El nombre de la leccion es obligatorio!')
            .typeError('¡El nombre de la leccion es obligatorio.'),
        sesionVivo: Yup.object().when('tipo', {
            is: (tipo: LeccionTipoEnum) => tipo === LeccionTipoEnum.SESION_VIVO,
            then: () => Yup.object().shape({
                fechaInicio: Yup.string().required('¡La fecha de inicio es obligatoria!'),
                fechaFin: Yup.string().required('¡La fecha de fin es obligatoria!'),
                online: Yup.boolean().required('¡El tipo de sesión es obligatorio!'),
            }),
            otherwise: () => Yup.object().shape({
                fechaInicio: Yup.string().notRequired().nullable(),
                fechaFin: Yup.string().notRequired().nullable(),
                online: Yup.boolean().notRequired().nullable(),
            })
        }),
        AIConfig: Yup.object().when('tipo', {
            is: (tipo: LeccionTipoEnum) => tipo === LeccionTipoEnum.AUTOCORREGIBLE,
            then: () => Yup.object().shape({
                tipo: Yup.string().required('¡El tipo de corrección es obligatorio!'),
                correccion: Yup.string().required('¡La guía de corrección es obligatoria!'),
                pregunta: Yup.string().required('¡La pregunta es obligatoria!'),
                puntuacion: Yup.number().required('¡La puntuación es obligatoria!'),
            }),
            otherwise: () => Yup.object().shape({
                tipo: Yup.string().notRequired().nullable(),
                correccion: Yup.string().notRequired().nullable(),
                pregunta: Yup.string().notRequired().nullable(),
                puntuacion: Yup.number().notRequired().nullable(),
            })
        })
    });

    const handleSubmit = async (values: any) => {
        if (
            (values?.tipo !== LeccionTipoEnum.FICHERO && (values?.tipo !== LeccionTipoEnum.ENTREGABLE && values?.subtipo !== SubtipoLeccion?.FICHERO))
            &&
            (!values?.contenido ||
                values?.contenido === "" ||
                values?.contenido === "<p><br></p>" ||
                values?.contenido === " "
            )
        ) {
            toastNotify(toast, StatusEnumTypes.WARNING, "Debe ingresar contenido para crear la lección")
            return
        } else if (
            (values?.tipo === LeccionTipoEnum.FICHERO || (values?.tipo === LeccionTipoEnum.ENTREGABLE && values?.subtipo === SubtipoLeccion.FICHERO)) && !file
        ) {
            toastNotify(toast, StatusEnumTypes.WARNING, "Debe subir un adjunto, para crear la lección")
            return
        }

        if (values?.sesionVivo?.fechaInicio && values?.sesionVivo?.fechaFin) {
            const fechaInicio = new Date(values.sesionVivo.fechaInicio);
            const fechaFin = new Date(values.sesionVivo.fechaFin);

            const horaInicio = fechaInicio.getHours();
            const minutosInicio = fechaInicio.getMinutes();
            const horaFin = fechaFin.getHours();
            const minutosFin = fechaFin.getMinutes();

            if (horaInicio === horaFin && minutosInicio === minutosFin) {
                toastNotify(toast, StatusEnumTypes.WARNING, "La Hora Inicio y la Hora Fin deben ser distintas")
                return;
            }
        }

        if (values?.contenido?.includes("<iframe") ||
            values?.contenido?.includes("</iframe>")) {
            toastNotify(toast, StatusEnumTypes.WARNING, "Debe ingresar solo la URL del contenido embebido")
            return
        }

        if (values?.tipo === LeccionTipoEnum.AUTOCORREGIBLE) {
            if (!values?.AIConfig?.tipo || !values?.AIConfig?.correccion || !values?.AIConfig?.pregunta || !values?.AIConfig?.puntuacion) {
                toastNotify(toast, StatusEnumTypes.WARNING, "Debe ingresar el tipo de corrección, la guía de corrección, la pregunta y la puntuación")
                return
            }
        }

        setIsLoading(true)

        let newLeccion: Partial<LeccionInt> = {
            moduloId: values?.moduloId,
            nombre: values?.nombre.trim(),
            publicado: values?.publicado,
            orden: values?.orden,
            tipo: values?.tipo,
            descripcion: values?.descripcion,
            contenido: values?.contenido,
        }

        if (values.tipo === LeccionTipoEnum.ENTREGABLE) {
            newLeccion = {
                ...newLeccion,
                subtipo: values?.subtipo
            }
        } else if (values.tipo === LeccionTipoEnum.AUTOCORREGIBLE) {
            newLeccion = {
                ...newLeccion,
                tipo: LeccionTipoEnum.ENTREGABLE,
                subtipo: values?.subtipo
            }
        } else if (values.tipo === LeccionTipoEnum.SESION_VIVO) {
            if (values?.sesionVivo?.fechaInicio && values?.sesionVivo?.fechaFin) {
                if (values?.sesionVivo?.fechaInicio > values?.sesionVivo?.fechaFin) {
                    toastNotify(toast, StatusEnumTypes.WARNING, 'La fecha de inicio no puede ser posterior a la fecha de fin');
                    setIsLoading(false)
                    return;
                }
            }

            newLeccion = {
                ...newLeccion,
                sesionVivo: {
                    fechaInicio: values?.sesionVivo?.fechaInicio,
                    fechaFin: values?.sesionVivo?.fechaFin,
                    online: values?.sesionVivo?.online,
                }
            }
        }

        addLeccion({
            leccion: newLeccion,
            client: client
        })
            .then(async (response) => {
                const leccion = response?.data?.data;

                if (file) {
                    await loadContenido({
                        id: leccion?.id as string,
                        client: client,
                        file: file,
                    })
                        .catch((error: any) => toastNotify(toast, StatusEnumTypes.WARNING, "Lección creada, pero no se pudo cargar el contenido, vuelva a intentarlo."))
                }

                if (values?.tipo === LeccionTipoEnum.AUTOCORREGIBLE) {
                    await updateLeccion({
                        id: leccion?.id as string,
                        data: {
                            AIConfig: {
                                tipo: values?.AIConfig?.tipo,
                                correccion: values?.AIConfig?.correccion,
                                pregunta: values?.AIConfig?.pregunta,
                                puntuacion: values?.AIConfig?.puntuacion
                            }
                        },
                        client: client
                    }).then((res) => {
                        const updatedLeccion = res?.data?.data;

                        setSelected({
                            type: TipoSelect.LECCION,
                            data: updatedLeccion
                        })
                    })
                } else {
                    setSelected({
                        type: TipoSelect.LECCION,
                        data: leccion
                    })
                }

                setRefreshLeccion(true)
                setRefreshModulos()

                setSelected({
                    type: TipoSelect.LECCION,
                    data: leccion
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const tipoContenido: TipoContenido[] = [
        {
            tipo: LeccionTipoEnum.FICHERO,
            subtipo: null,
            icon: HiOutlinePaperClip,
            titulo: "Fichero",
            descripcion: "Sube documentos, imágenes o videos directamente, como archivos PDF, Word, Excel, PowerPoint, o cualquier tipo de fichero.",
            isAllowed: true
        },
        {
            tipo: LeccionTipoEnum.EMBEBIDO,
            subtipo: null,
            icon: HiOutlineDocumentDownload,
            titulo: "Contenido Embebido",
            descripcion: "Incorpora contenido desde plataformas externas, como videos de YouTube, presentaciones de Slideshare, o documentos de Google Drive, que se pueden visualizar directamente en la plataforma.",
            isAllowed: true
        },
        {
            tipo: LeccionTipoEnum.TEXTO,
            subtipo: null,
            icon: HiOutlineDocumentText,
            titulo: "Texto",
            descripcion: "Agrega texto plano o contenido en formato Markdown para proporcionar información detallada o instrucciones dentro de la plataforma.",
            isAllowed: true
        },
        {
            tipo: LeccionTipoEnum.URL,
            subtipo: null,
            icon: HiOutlineLink,
            titulo: "URL",
            descripcion: "Añade enlaces a recursos externos como artículos, libros, o cualquier página web útil para complementar el aprendizaje.",
            isAllowed: true
        },
        {
            tipo: LeccionTipoEnum.ENTREGABLE,
            subtipo: null,
            icon: HiOutlineClipboard,
            titulo: "Entregable",
            descripcion: "Tarea que los estudiantes deben completar y entregar, que luego sera evaluado por un tutor.",
            isAllowed: moduloAI
        },
        {
            tipo: LeccionTipoEnum.AUTOCORREGIBLE,
            subtipo: null,
            icon: PiExam,
            titulo: "Autocorregible",
            descripcion: "Ejercicio para que los estudiantes realicen con formatos de respuesta de tipo código, texto o audio, en el que la IA realizará una corrección en tiempo real de la respuesta.",
            isAllowed: moduloAI
        },
        {
            tipo: LeccionTipoEnum.SESION_VIVO,
            subtipo: null,
            icon: HiOutlinePlay,
            titulo: "Sesión en vivo",
            descripcion: "Sesión en vivo para interacción directa, discusión y aprendizaje en tiempo real.",
            isAllowed: true
        }
    ]

    const subTipoContenido: TipoContenido[] = [
        {
            tipo: LeccionTipoEnum.ENTREGABLE,
            subtipo: SubtipoLeccion.EMBEBIDO,
            icon: HiOutlineClipboard,
            titulo: "Entregable Embebido",
            descripcion: "Tarea que los estudiantes deben completar y entregar, incorpora contenido desde plataformas externas.",
            isAllowed: curso?.tutorizado || false
        },
        {
            tipo: LeccionTipoEnum.ENTREGABLE,
            subtipo: SubtipoLeccion.TEXTO,
            icon: HiOutlineClipboard,
            titulo: "Entregable Texto",
            descripcion: "Tarea que los estudiantes deben completar y entregar, agrega texto plano o contenido en formato Markdown para proporcionar información detallada.",
            isAllowed: curso?.tutorizado || false
        },
        {
            tipo: LeccionTipoEnum.ENTREGABLE,
            subtipo: SubtipoLeccion.FICHERO,
            icon: HiOutlineClipboard,
            titulo: "Entregable Fichero",
            descripcion: "Tarea que los estudiantes deben completar y entregar, en formato de documentos, imágenes o videos, archivos PDF, Word, Excel, PowerPoint, o cualquier tipo de fichero.",
            isAllowed: curso?.tutorizado || false
        },
        {
            tipo: LeccionTipoEnum.AUTOCORREGIBLE,
            subtipo: SubtipoLeccion.EMBEBIDO,
            icon: PiExam,
            titulo: "Autocorregible Embebido",
            descripcion: "Ejercicio autocorregible que incorpora contenido desde plataformas externas, en el que la IA realizará una corrección en tiempo real de la respuesta.",
            isAllowed: moduloAI
        },
        {
            tipo: LeccionTipoEnum.AUTOCORREGIBLE,
            subtipo: SubtipoLeccion.TEXTO,
            icon: PiExam,
            titulo: "Autocorregible Texto",
            descripcion: "Ejercicio autocorregible que agrega texto plano o contenido en formato Markdown para proporcionar información detallada, en el que la IA realizará una corrección en tiempo real de la respuesta.",
            isAllowed: moduloAI
        },
        {
            tipo: LeccionTipoEnum.AUTOCORREGIBLE,
            subtipo: SubtipoLeccion.FICHERO,
            icon: PiExam,
            titulo: "Autocorregible Fichero",
            descripcion: "Ejercicio autocorregible que en formato de documentos, imágenes o videos, archivos PDF, Word, Excel, PowerPoint, o cualquier tipo de fichero, en el que la IA realizará una corrección en tiempo real de la respuesta.",
            isAllowed: moduloAI
        },
    ]

    return (
        <Flex
            w="100%"
            direction="column"
        >
            <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            {(step === StepTypes.SELECT_TIPO || step === StepTypes.SELECT_SUBTIPO) &&
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb="10px"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                        color="pureblack"
                                    >
                                        {
                                            step === StepTypes.SELECT_TIPO
                                                ? "Tipo de contenido"
                                                : values?.tipo === LeccionTipoEnum.ENTREGABLE
                                                    ? "Contenido del entregable"
                                                    : "Contenido de la autocorrección"
                                        }
                                    </Text>

                                    <Button
                                        px="10px"
                                        py="10px"
                                        h="fit-content"
                                        w="fit-content"
                                        gap="10px"
                                        rounded="5px"
                                        border="1px solid"
                                        borderColor={"secondary"}
                                        fontSize="14px"
                                        fontWeight={"500"}
                                        color="secondary"
                                        bg="purewhite"
                                        onClick={onOpenAlert}
                                        disabled={isLoading}
                                    >
                                        Cancelar
                                    </Button>
                                </Flex>
                            }

                            <Flex
                                css={{
                                    '&::-webkit-scrollbar': {
                                        width: '3px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: '8px',
                                        background: `light_grey`,
                                    },
                                }}
                                overflow="auto"
                                h={`calc(100vh - ${step === StepTypes.SELECT_TIPO ? "240px" : "230px"})`}
                            >
                                {
                                    step === StepTypes.SELECT_TIPO &&
                                    <Grid
                                        templateColumns="repeat(2, 1fr)"
                                        columnGap="40px"
                                        rowGap="40px"
                                        h="fit-content"
                                        w="100%"
                                    >
                                        {tipoContenido?.map((item: TipoContenido, index: number) => (
                                            item?.isAllowed &&
                                            <Flex
                                                key={index}
                                                gap="20px"
                                                alignItems="start"
                                                p="10px"
                                                rounded="5px"
                                                cursor="pointer"
                                                _hover={{ bg: "variant", transition: "all 0.2s" }}
                                                onClick={() => {
                                                    formik.setFieldValue('tipo', item?.tipo)

                                                    item?.tipo === LeccionTipoEnum.ENTREGABLE || item?.tipo === LeccionTipoEnum.AUTOCORREGIBLE
                                                        ? setStep(StepTypes.SELECT_SUBTIPO)
                                                        : setStep(StepTypes.SELECT_CONTENT)
                                                }}
                                            >
                                                <Flex
                                                    w="fit-content"
                                                    h="fit-content"
                                                    rounded="5px"
                                                    bg="purewhite"
                                                    shadow="0px 7.178px 7.068px 0px rgba(211, 213, 216, 0.30)"
                                                    p="10px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Icon as={item?.icon} boxSize="24px" />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                    gap="8px"
                                                >
                                                    <Text
                                                        fontSize="17px"
                                                        fontWeight="400"
                                                        color="pureblack"
                                                    >
                                                        {item?.titulo}
                                                    </Text>

                                                    <Text
                                                        fontSize="17px"
                                                        fontWeight="300"
                                                        color="#666E7D"
                                                    >
                                                        {item?.descripcion}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </Grid>
                                }

                                {
                                    step === StepTypes.SELECT_SUBTIPO &&
                                    <Grid
                                        templateColumns="repeat(2, 1fr)"
                                        columnGap="40px"
                                        rowGap="40px"
                                        h="fit-content"
                                        w="100%"
                                    >
                                        {subTipoContenido?.filter((item: TipoContenido) => item?.tipo === values?.tipo)?.map((item: TipoContenido, i: number) => (
                                            item?.isAllowed &&
                                            <Flex
                                                key={i}
                                                gap="20px"
                                                alignItems="start"
                                                p="10px"
                                                rounded="5px"
                                                cursor="pointer"
                                                _hover={{ bg: "variant", transition: "all 0.2s" }}
                                                onClick={() => {
                                                    formik.setFieldValue('subtipo', item?.subtipo)
                                                    setStep(StepTypes.SELECT_CONTENT)
                                                }}
                                            >
                                                <Flex
                                                    w="fit-content"
                                                    h="fit-content"
                                                    rounded="5px"
                                                    bg="purewhite"
                                                    shadow="0px 7.178px 7.068px 0px rgba(211, 213, 216, 0.30)"
                                                    p="10px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Icon as={item?.icon} boxSize="24px" />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                    gap="8px"
                                                >
                                                    <Text
                                                        fontSize="17px"
                                                        fontWeight="400"
                                                        color="pureblack"
                                                    >
                                                        {item?.titulo}
                                                    </Text>

                                                    <Text
                                                        fontSize="17px"
                                                        fontWeight="300"
                                                        color="#666E7D"
                                                    >
                                                        {item?.descripcion}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </Grid>
                                }

                                {
                                    step === StepTypes.SELECT_CONTENT &&
                                    <Flex
                                        w="100%"
                                        direction="column"
                                        gap="20px"
                                    >
                                        <Flex
                                            h="40px"
                                            w="100%"
                                            alignItems="center"
                                            justifyContent={"space-between"}
                                        >
                                            {!editName ?
                                                <Flex
                                                    alignItems="center"
                                                    gap="10px"
                                                    mt="2px"
                                                    onClick={() => setEditName(true)}
                                                    pt="2px"
                                                    pl="2px"
                                                >
                                                    <Text
                                                        fontSize="24px"
                                                        fontWeight="400"
                                                        color="pureblack"
                                                        pl="2px"
                                                    >
                                                        {values?.nombre}
                                                    </Text>

                                                    <Icon
                                                        as={FiEdit3}
                                                        boxSize="18px"
                                                        color="pureblack"
                                                        cursor="pointer"
                                                    />
                                                </Flex>
                                                :
                                                <Flex

                                                    pt="2px"
                                                    pl="2px"
                                                >
                                                    <FormInput
                                                        name="nombre"
                                                        isBlockError
                                                        onBlur={() => setEditName(false)}
                                                        ui={{
                                                            styleInput: {
                                                                border: "none",
                                                                bg: "transparent",
                                                                width: "auto",
                                                                fontSize: "24px",
                                                                fontWeight: "400",
                                                                color: "pureblack",
                                                                pl: "2px",
                                                            },
                                                            focusInput: {
                                                                border: "none",
                                                                boxShadow: `0 0 0 1px ${colores.variant}`,
                                                            },
                                                        }}
                                                    />
                                                </Flex>
                                            }

                                            <Flex
                                                gap="15px"
                                                alignItems={"center"}
                                                justifyContent="end"
                                            >
                                                <Button
                                                    px="10px"
                                                    py="10px"
                                                    h="fit-content"
                                                    w="fit-content"
                                                    gap="10px"
                                                    rounded="5px"
                                                    border="1px solid"
                                                    borderColor={"secondary"}
                                                    fontSize="14px"
                                                    fontWeight={"500"}
                                                    color="secondary"
                                                    bg="purewhite"
                                                    onClick={onOpenAlert}
                                                    disabled={isLoading}
                                                >
                                                    Cancelar
                                                </Button>

                                                <Button
                                                    px="10px"
                                                    py="10px"
                                                    h="fit-content"
                                                    w="fit-content"
                                                    gap="10px"
                                                    rounded="5px"
                                                    border="1px solid"
                                                    borderColor={"variant"}
                                                    fontSize="14px"
                                                    fontWeight={"500"}
                                                    color="purewhite"
                                                    bg="secondary"
                                                    _hover={{ bg: "variant", borderColor: "variant" }}
                                                    type="submit"
                                                    isLoading={isLoading}
                                                >
                                                    Guardar
                                                </Button>
                                            </Flex>
                                        </Flex>

                                        <InformationBox
                                            noHeader
                                        >
                                            <Flex
                                                direction="column"
                                                py="20px"
                                                px="20px"
                                                gap="30px"
                                            >
                                                {(values?.tipo === LeccionTipoEnum.URL) &&
                                                    <Flex
                                                        direction="column"
                                                        gap="15px"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            gap="5px"
                                                        >
                                                            <Text
                                                                color="pureblack"
                                                                fontSize="16px"
                                                                fontWeight="400"
                                                            >
                                                                Contenido lección
                                                            </Text>

                                                            <InformationComponent
                                                                tooltip={{
                                                                    content:
                                                                        <Flex
                                                                            direction="column"
                                                                        >
                                                                            <Text
                                                                                textAlign="center"
                                                                                fontSize="16px"
                                                                                fontWeight={"600"}
                                                                                mb="10px"
                                                                            >
                                                                                Lección de tipo URL
                                                                            </Text>

                                                                            <UnorderedList
                                                                                pl="10px"
                                                                                display="flex"
                                                                                flexDirection={"column"}
                                                                                gap="10px"
                                                                                fontSize="14px"
                                                                                fontWeight={"400"}
                                                                            >
                                                                                <ListItem>
                                                                                    Inserte la <Text as={"strong"}>URL</Text> a donde se redireccionara o descargara el recurso de la leccion.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Si estás obteniendo el <Text as={"strong"}>URL</Text> para una descarga de una nube como OneDrive, Google Drive, entre otras, <Text as={"strong"}>asegúrate de compartir</Text> la URL como <Text as={"strong"}>público</Text>. Esto garantizará que la lección se cargue correctamente en la aplicación.
                                                                                </ListItem>
                                                                            </UnorderedList>
                                                                        </Flex>,
                                                                    placement: "right",
                                                                    hasArrow: true,
                                                                    isDisabled: false,
                                                                    styles: { rounded: "8px", p: "10px" }
                                                                }}
                                                                icon={BiInfoCircle}
                                                            />
                                                        </Flex>

                                                        <FormTextarea
                                                            name="contenido"
                                                            placeholder="Introduce el enlace al recurso"
                                                            noHead={true}
                                                            ui={{
                                                                minHeigh: "20px",
                                                                styles: {
                                                                    border: "1px solid",
                                                                    borderColor: "#E2E8F0",
                                                                    borderRadius: "5px",
                                                                    color: "font",
                                                                    padding: "5px",
                                                                    maxHeight: "100px",
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                }

                                                {values?.tipo === LeccionTipoEnum.SESION_VIVO &&
                                                    <Flex
                                                        direction="column"
                                                        gap="15px"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            gap="5px"
                                                        >
                                                            <Text
                                                                color="pureblack"
                                                                fontSize="16px"
                                                                fontWeight="400"
                                                            >
                                                                Información de la sesión
                                                            </Text>

                                                            <InformationComponent
                                                                tooltip={{
                                                                    content:
                                                                        <Flex
                                                                            direction="column"
                                                                        >
                                                                            <Text
                                                                                textAlign="center"
                                                                                fontSize="16px"
                                                                                fontWeight={"600"}
                                                                                mb="10px"
                                                                            >
                                                                                Lección de tipo Sesión en vivo
                                                                            </Text>

                                                                            <UnorderedList
                                                                                pl="10px"
                                                                                display="flex"
                                                                                flexDirection={"column"}
                                                                                gap="10px"
                                                                                fontSize="14px"
                                                                                fontWeight={"400"}
                                                                            >
                                                                                <ListItem>
                                                                                    Indique si la sesión es <Text as={"strong"}>online</Text> o <Text as={"strong"}>presencial</Text>.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Indique una <Text as={"strong"}>fecha de inicio</Text> y una <Text as={"strong"}>fecha de fin</Text> para la sesión.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Si la sesión es <Text as={"strong"}>presencial</Text>, inserte la <Text as={"strong"}>dirección</Text> de la sesión.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Si la sesión es <Text as={"strong"}>online</Text>, inserte la <Text as={"strong"}>URL</Text> de la sesión.
                                                                                </ListItem>
                                                                            </UnorderedList>
                                                                        </Flex>,
                                                                    placement: "right",
                                                                    hasArrow: true,
                                                                    isDisabled: false,
                                                                    styles: { rounded: "8px", p: "10px" }
                                                                }}
                                                                icon={BiInfoCircle}
                                                            />
                                                        </Flex>

                                                        <Flex
                                                            direction="column"
                                                        >
                                                            <FormLabel
                                                                htmlFor="sesionVivo.online"
                                                                display="flex"
                                                                gap="3px"
                                                                color="black"
                                                                fontSize="14px"
                                                                fontWeight="400"
                                                            >
                                                                Tipo de sesión
                                                            </FormLabel>

                                                            <CustomSwitch
                                                                value={values?.sesionVivo?.online}
                                                                setValue={() => formik.setFieldValue('sesionVivo.online', !values.sesionVivo.online)}
                                                                optionLeft="Online"
                                                                optionRight="Presencial"
                                                                height="25px"
                                                                widthLabel="100%"
                                                                widthSwitch="100%"
                                                                padding="0"
                                                            />
                                                        </Flex>

                                                        <Flex
                                                            gap="15px"
                                                        >
                                                            <FormDateInput
                                                                locale="es"
                                                                isDateTime
                                                                name="sesionVivo.fechaInicio"
                                                                label="Fecha y Hora inicio"
                                                                onChange={formik.setFieldValue}
                                                                placeholder="Día/Mes/Año"
                                                                ui={{
                                                                    styleLabel: {
                                                                        display: "flex",
                                                                        gap: "3px",
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                    }
                                                                }}
                                                            />

                                                            <FormNewTimeInput
                                                                name="sesionVivo.fechaFin"
                                                                label="Hora Fin"
                                                                onChange={(time) => formik.setFieldValue('sesionVivo.fechaFin', time)}
                                                                disabledTooltip="No se puede seleccionar una hora de fin si no se ha seleccionado una fecha de inicio"
                                                                isDisabled={!values?.sesionVivo?.fechaInicio}
                                                                date={values?.sesionVivo?.fechaInicio}
                                                                hoursOffSet={1}
                                                            />

                                                        </Flex>

                                                        <FormTextarea
                                                            name="contenido"
                                                            placeholder={values?.sesionVivo?.online ? "Introduce el link de la sesión en vivo" : "Introduce la ubicación de la sesión"}
                                                            label={values?.sesionVivo?.online ? "Link de la sesión en vivo" : "Ubicación de la sesión"}
                                                            ui={{
                                                                minHeigh: "20px",
                                                                styles: {
                                                                    border: "1px solid",
                                                                    borderColor: "#E2E8F0",
                                                                    borderRadius: "5px",
                                                                    color: "font",
                                                                    padding: "5px",
                                                                    maxHeight: "100px",
                                                                },
                                                                styleLabel: {
                                                                    display: "flex",
                                                                    gap: "3px",
                                                                    color: "black",
                                                                    fontSize: "14px",
                                                                    fontWeight: "400"
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                }

                                                {(values?.tipo === LeccionTipoEnum.FICHERO || (values?.tipo === LeccionTipoEnum.ENTREGABLE && values?.subtipo === SubtipoLeccion.FICHERO)) &&
                                                    <Flex
                                                        w="100%"
                                                        direction="column"
                                                    >
                                                        <DropzoneComponent
                                                            file={file}
                                                            setFile={setFile}
                                                        />
                                                    </Flex>
                                                }


                                                {
                                                    (values?.tipo === LeccionTipoEnum.EMBEBIDO || (values?.tipo === LeccionTipoEnum.ENTREGABLE && values?.subtipo === SubtipoLeccion.EMBEBIDO)) &&
                                                    <Flex
                                                        direction="column"
                                                        gap="15px"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            gap="5px"
                                                        >
                                                            <Text
                                                                color="pureblack"
                                                                fontSize="16px"
                                                                fontWeight="400"
                                                            >
                                                                Contenido lección
                                                            </Text>

                                                            <InformationComponent
                                                                tooltip={{
                                                                    content:
                                                                        <Flex
                                                                            direction="column"
                                                                        >
                                                                            <Text
                                                                                textAlign="center"
                                                                                fontSize="16px"
                                                                                fontWeight={"600"}
                                                                                mb="10px"
                                                                            >
                                                                                Lección de tipo {values?.tipo === LeccionTipoEnum.ENTREGABLE ? "Entregable" : "Embebido"}
                                                                            </Text>


                                                                            <UnorderedList
                                                                                pl="10px"
                                                                                display="flex"
                                                                                flexDirection={"column"}
                                                                                gap="10px"
                                                                                fontSize="14px"
                                                                                fontWeight={"400"}
                                                                            >
                                                                                {values?.tipo === LeccionTipoEnum.ENTREGABLE ?
                                                                                    <>
                                                                                        <ListItem>
                                                                                            Insete la <Text as={"strong"}>URL</Text> del ejercicio a embeber.
                                                                                        </ListItem>

                                                                                        <ListItem>
                                                                                            Las lecciones de tipo <Text as={"strong"}>Entregable</Text> requieren una entrega en el campus, el alumno debera subir un archivo con el ejercicio resuelto donde el tutor podra calificarlo.
                                                                                        </ListItem>
                                                                                    </>
                                                                                    :
                                                                                    <ListItem>
                                                                                        Insete la <Text as={"strong"}>URL</Text> del contenido a embeber.
                                                                                    </ListItem>
                                                                                }

                                                                                <ListItem>
                                                                                    Recuerde que si está obteniendo la <Text as={"strong"}>URL</Text> de una plataforma externa como: "Vimeo", "Slides", "OneDrive", "Google Drive", entre otras. <Text as={"strong"}>Asegúrese de compartir</Text> la URL como <Text as={"strong"}>pública</Text>. Esto garantizará que el ejercicio se cargue correctamente en la aplicación.
                                                                                </ListItem>
                                                                            </UnorderedList>
                                                                        </Flex>,
                                                                    placement: "right",
                                                                    hasArrow: true,
                                                                    isDisabled: false,
                                                                    styles: { rounded: "8px", p: "10px" }
                                                                }}
                                                                icon={BiInfoCircle}
                                                            />
                                                        </Flex>

                                                        <FormTextarea
                                                            name="contenido"
                                                            noHead={true}
                                                            ui={{
                                                                minHeigh: "20px",
                                                                styles: {
                                                                    border: "1px solid",
                                                                    borderColor: "#E2E8F0",
                                                                    borderRadius: "5px",
                                                                    color: "font",
                                                                    padding: "5px",
                                                                    maxHeight: "100px",
                                                                }
                                                            }}
                                                            placeholder={"introduce la URL del contenido a embeber"}
                                                        />
                                                    </Flex>
                                                }

                                                {
                                                    (values?.tipo === LeccionTipoEnum.TEXTO ||
                                                        ((values?.tipo === LeccionTipoEnum.ENTREGABLE || values?.tipo === LeccionTipoEnum.AUTOCORREGIBLE)
                                                            && values?.subtipo === SubtipoLeccion.TEXTO)) &&
                                                    <Flex
                                                        direction="column"
                                                        gap="15px"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            gap="5px"
                                                        >
                                                            <Text
                                                                color="pureblack"
                                                                fontSize="16px"
                                                                fontWeight="400"
                                                            >
                                                                Contenido lección
                                                            </Text>

                                                            <InformationComponent
                                                                tooltip={{
                                                                    content:
                                                                        <Flex
                                                                            direction="column"
                                                                        >
                                                                            <Text
                                                                                textAlign="center"
                                                                                fontSize="16px"
                                                                                fontWeight={"600"}
                                                                                mb="10px"
                                                                            >
                                                                                Lección de tipo Texto
                                                                            </Text>

                                                                            <UnorderedList
                                                                                pl="10px"
                                                                                display="flex"
                                                                                flexDirection={"column"}
                                                                                gap="10px"
                                                                                fontSize="14px"
                                                                                fontWeight={"400"}
                                                                                mb="10px"
                                                                            >
                                                                                <ListItem>
                                                                                    Insertar <Text as="strong">texto plano</Text>.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Inserta Markdown donde puedes utilizar <Text as="strong">negrita</Text>, <Text as="i">cursiva</Text>, y <Text as="u">subrayado</Text> para resaltar texto.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Insertar <Text as="strong">código</Text>.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Es posible crear listas ordenadas o desordenadas.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Puedes agregar distintos tamaños de letras y darle estilos.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Agrega enlaces en tus textos.
                                                                                </ListItem>
                                                                            </UnorderedList>

                                                                            <Text
                                                                                textAlign="center"
                                                                                fontSize="14px"
                                                                                fontWeight={"700"}
                                                                            >
                                                                                Todo esto puedes hacerlo con la barra de herramientas que se encuentra en la parte superior del editor.
                                                                            </Text>
                                                                        </Flex>,
                                                                    placement: "right",
                                                                    hasArrow: true,
                                                                    isDisabled: false,
                                                                    styles: { rounded: "8px", p: "10px" }
                                                                }}
                                                                icon={BiInfoCircle}
                                                            />
                                                        </Flex>

                                                        <FormTextEditor
                                                            name="contenido"
                                                            placeholder="Introduce el texto"
                                                            onChange={formik.setFieldValue}
                                                            stylesEditor={{
                                                                borderRadius: "10px",
                                                            }}
                                                        />
                                                    </Flex>
                                                }

                                                {values?.tipo === LeccionTipoEnum.AUTOCORREGIBLE &&
                                                    <Flex
                                                        direction="column"
                                                        gap="15px"
                                                    >
                                                        <FormSelect
                                                            label="Formato de corrección"
                                                            name="AIConfig.tipo"
                                                            options={[
                                                                { label: "Texto", value: "texto" },
                                                                { label: "Audio", value: "audio" },
                                                                { label: "Código", value: "codigo" },
                                                            ]}
                                                            ui={{
                                                                formSelectStyles: formSelectStyles
                                                            }}
                                                            placeholder="Seleccione el formato de corrección"
                                                        />

                                                        <FormInput
                                                            name="AIConfig.puntuacion"
                                                            label="Puntuación"
                                                            placeholder="Puntuación con la que se supera el ejercicio"
                                                            type="number"
                                                            min={1}
                                                            max={100}
                                                        />

                                                        <FormTextarea
                                                            name="AIConfig.pregunta"
                                                            label="Pregunta a corregir"
                                                            ui={{
                                                                minHeigh: "300px",
                                                                styles: {
                                                                    border: "1px solid",
                                                                    borderColor: "#E2E8F0",
                                                                    borderRadius: "5px",
                                                                    color: "font",
                                                                    padding: "5px",
                                                                    maxHeight: "300px",
                                                                }
                                                            }}
                                                            placeholder={"Pregunta que se debe tener en cuenta para a la hora de la corrección del ejercicio"}
                                                        />

                                                        <FormTextarea
                                                            name="AIConfig.correccion"
                                                            label="Guía de corrección"
                                                            ui={{
                                                                minHeigh: "300px",
                                                                styles: {
                                                                    border: "1px solid",
                                                                    borderColor: "#E2E8F0",
                                                                    borderRadius: "5px",
                                                                    color: "font",
                                                                    padding: "5px",
                                                                    maxHeight: "300px",
                                                                }
                                                            }}
                                                            placeholder={"Indica la guía de como se debe corregir el ejercicio y puntos a tener en cuenta"}
                                                        />
                                                    </Flex>
                                                }

                                                {values?.tipo === LeccionTipoEnum.ENTREGABLE &&
                                                    <Flex
                                                        direction="column"
                                                        gap="15px"
                                                    >
                                                        <Text
                                                            color="pureblack"
                                                            fontSize="16px"
                                                            fontWeight="400"
                                                        >
                                                            Guía de entrega del ejercicio
                                                        </Text>

                                                        <FormTextarea
                                                            name="descripcion"
                                                            placeholder="Indique al alumno como debe entregar el ejercicio"
                                                            noHead
                                                            ui={{
                                                                minHeigh: '150px',
                                                                styles: {
                                                                    borderRadius: "5px",
                                                                    border: "1px solid #EAEAEA",
                                                                    padding: "10px",
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                }
                                            </Flex>
                                        </InformationBox>
                                    </Flex>
                                }
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={() => {
                    setStep(StepTypes.SELECT_TIPO);
                    setSelected(undefined)

                    setRefreshLeccion(true)
                    onCloseAlert();
                }}
            />
        </Flex >
    )
}
