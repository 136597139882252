import { UseToastOptions } from "@chakra-ui/react";
import { TipoXML } from "../Types/TipoXML";
import { downloadXML } from "../../middlewares/adjuntos.middleware";
import { handleErrors } from "./handleErrors";
import { toastNotify } from "./toastNotify";
import { StatusEnumTypes } from "../Types/StatusEnumTypes";

interface HandleDownloadXMLProps {
    selectedItems: any[];
    tipo: TipoXML;
    client: any;
    fileName: string;
    toast?: any;
    setLoading?: (loading: boolean) => void;
}

export const handleDownloadXML = async ({
    selectedItems,
    tipo,
    client,
    fileName,
    toast,
    setLoading
}: HandleDownloadXMLProps) => {
    if (!selectedItems?.length) return;
    setLoading?.(true);

    const gruposList = selectedItems?.map((g) => g?.id);

    try {
        const response = await downloadXML({
            data: {
                tipo,
                grupos: gruposList,
            },
            client
        });

        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xml`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    } catch (error: any) {
        if (toast) {
            const errors = handleErrors(
                error?.response?.data?.errors,
                error?.response?.status
            );
            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message));
        }
    } finally {
        setLoading?.(false);
    }
} 