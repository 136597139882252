import { Button, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { useClient } from "../../../../../../shared/context/client.context";
import { getInformes } from "../../../../../../shared/middlewares/informes.middleware";
import { MatriculasInt } from "../../../../../../interfaces/MatriculasInt";
import { InformesEnumTypes } from "../../../../../../shared/utils/Types/InformesEnumTypes";
import { handleErrors } from "../../../../../../shared/utils/functions/handleErrors";
import { CustomMultiSelect } from "../../../../../../shared/components/CustomElements/CustomMultiSelect";
import { useDataRelationship } from "../../../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../../shared/utils/Types/FiltrosEnum";
import { useModulos } from "../../../../../../shared/hooks/ModuloFundae";
import { Table } from "../../../../../../shared/components/Table/Table";
import { Filters } from "../../../../../../shared/components/Filters/Filters";
import { InformesColumns } from "../../../../components/TabsColumns/InformesColumns";
import { EntityTypes, useQuerySession } from "../../../../../../shared/hooks/useQuerySession";
import { useUserPolicy } from "../../../../../../shared/hooks/PermissionPolicy";
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { useData } from "../../../../../../shared/hooks/useData";
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum";
import { EmpresasInt } from "../../../../../../interfaces/EmpresasInt";
import { GruposInt } from "../../../../../../interfaces/GruposInt";

interface InformesItemsInt {
    nombre: string;
    tipo: string;
}
interface Props {
    grupo: GruposInt;
}

export const TabInformes = ({
    grupo,
}: Props) => {
    const { gestion, contenido } = useUserPolicy()
    const { user } = useAuthContex()
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.INFORMES_MATRICULA });
    const { moduloFUNDAE } = useModulos();
    const client = useClient();
    const toast = useToast();
    const { data: informes, loading } = useDataRelationship({
        id: grupo?.id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "false",
        },
        query: query
    })
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false)
    const [selectedInformes, setSelectedInformes] = useState<any[]>([]);
    const [selectedMatriculas, setSelectedMatriculas] = useState<MatriculasInt[]>([]);
    const [disabledDiploma, setDisabledDiploma] = useState<{ id: string; disabled: boolean }[]>([])

    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        const disabledDiplomaData: { id: string; disabled: boolean; }[] = []

        if (empresas && informes) {
            informes?.data?.forEach((matricula: MatriculasInt) => {
                const empresaFactura = empresas?.data?.find((e: EmpresasInt) => e?.id === matricula?.empresaFacturaId)

                if (empresaFactura && empresaFactura?.imagen?.url) disabledDiplomaData.push({ id: matricula?.id as string, disabled: false })
                else disabledDiplomaData.push({ id: matricula?.id as string, disabled: true })
            })
        }

        setDisabledDiploma(disabledDiplomaData)
    }, [empresas, informes])

    const informesBase: InformesItemsInt[] = [
        { nombre: 'Expediente y Conexión de actividad (Datos del alumno y del curso, y actividades realizadas con el registro de conexiones)', tipo: InformesEnumTypes.CONEXIONES_ACTIVIDAD },
        { nombre: 'Recibido diploma (Confirmación de que el alumno ha recibido el diploma)', tipo: InformesEnumTypes.RECIBI },
        { nombre: 'Conexiones (Datos e historial de las conexiones por actividad y por días)', tipo: InformesEnumTypes.CONEXIONES },
        { nombre: 'Diploma (Diploma del alumno)', tipo: InformesEnumTypes.DIPLOMA },
    ];

    const informesFundae: InformesItemsInt[] = [
        { nombre: 'Cuestionario final (Cuestionario de calidad de la formación)', tipo: InformesEnumTypes.CUESTIONARIO },
        { nombre: 'Conexión en horas laborales (Datos e historial de todas las conexiones en horario laboral)', tipo: InformesEnumTypes.CONEXIONES_HORARIO_LABORAL },
    ];

    const informesTutorizado: InformesItemsInt[] = [
        { nombre: 'Interacción foro (Datos, preguntas y respuestas creadas por el alumno en el foro)', tipo: InformesEnumTypes.FORO },
        { nombre: 'Interacción con el tutor (Historial de mensajes del alumno con el tutor y resolución de las actividades)', tipo: InformesEnumTypes.TUTOR },
        { nombre: 'Conexiones Formador (Datos e historial de las conexiones del formador)', tipo: InformesEnumTypes.CONEXIONES_FORMADOR },
        { nombre: 'Guía didáctica (Guía didáctica del curso)', tipo: InformesEnumTypes.GUIA_DIDACTICA },
        { nombre: 'Reseña profesional (Reseña del formador)', tipo: InformesEnumTypes.CV_TUTOR }
    ];

    const informesItems = grupo?.fundae
        ? [...informesBase, ...informesFundae, ...informesTutorizado]
        : grupo?.curso?.tutorizado
            ? [...informesBase, ...informesTutorizado]
            : [...informesBase]

    const downloadSelect = () => {
        setDownloadLoading(true)
        const informes: string[] = []

        selectedInformes?.map((informe: InformesItemsInt) => informes.push(informe?.tipo))

        const matriculas: {
            id: string;
            informes: string[];
            debug: boolean;
        }[] = []

        selectedMatriculas?.map((matricula: MatriculasInt) => {
            if (!matricula?.id) return

            matriculas.push({
                id: matricula?.id,
                informes: informes,
                debug: true,
            })
        })

        getInformes({
            data: {
                grupoId: grupo?.id as string,
                matriculas
            },
            client: client
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Los informes se están generando, recibirá una notificación con los enlaces de descarga"))
            .catch((error) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setSelectedMatriculas([])
                setDownloadLoading(false)
            })
    }

    return (
        <Flex direction="column" gap="20px">
            <Flex gap="15px" direction="column" mt="10px">
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    showBody={false}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                />
                {moduloFUNDAE &&
                    <Flex
                        gap="25px"
                        alignItems="center"
                    >
                        <Text
                            color="font"
                            fontSize="15px"
                            fontWeight="400"
                        >
                            Seleccione los informes a descargar
                        </Text>

                        <Flex>
                            <CustomMultiSelect
                                optionLabel="nombre"
                                options={informesItems}
                                selected={selectedInformes}
                                setSelected={setSelectedInformes}
                                maxSelectedLabels={6}
                            />
                        </Flex>

                        <Button
                            h="fit-content"
                            p="7px 12px"
                            bg="black"
                            color="white"
                            fontSize="14px"
                            fontWeight="600px"
                            isLoading={downloadLoading}
                            leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                            _hover={{ bg: "light_grey", color: "black" }}
                            isDisabled={selectedInformes?.length === 0 || selectedMatriculas?.length === 0}
                            onClick={downloadSelect}
                        >
                            Descargar
                        </Button>
                    </Flex>
                }
            </Flex>

            <Table
                data={informes?.data || []}
                columns={InformesColumns({
                    moduloFUNDAE,
                    grupoId: grupo?.id,
                    disabledDiploma
                })}
                isLoading={loading}
                total={informes?.meta?.total || 0}
                selection={selectedMatriculas}
                query={query}
                setQuery={setQuery}
                onSelectionChange={(e) => setSelectedMatriculas(e.value)}
            />
        </Flex>
    );
}