import { Badge, Button, Flex, Text } from "@chakra-ui/react";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";

interface Props {
    correosActivos: boolean;
    updateValue: (action: any) => void;
    requireAdmin?: boolean;
    isAdmin?: boolean;
}

export const CorreosActivosStatus = ({ correosActivos, updateValue, requireAdmin = false, isAdmin = false }: Props) => {
    const showButton = !correosActivos && (!requireAdmin || (requireAdmin && isAdmin));

    return (
        <>
            <Text
                color="font"
                fontSize="14px"
                fontWeight="600"
            >
                Correos Activos:
            </Text>

            <Badge
                color={correosActivos ? "success_main" : "font"}
                bg={correosActivos ? "success_bg" : "light_grey"}
                borderColor={correosActivos ? "success_variant" : "font"}
                borderWidth="1px"
                fontSize="10px"
                fontWeight="500"
                letterSpacing="0.2px"
                textTransform="uppercase"
                borderRadius="14px"
                p="5px 8px"
            >
                {correosActivos ? "Sí" : "No"}
            </Badge>

            {showButton && (
                <Button
                    p="7px 12px"
                    h="fit-content"
                    w="fit-content"
                    borderRadius="5px"
                    bg="secondary"
                    color="#FFF"
                    fontSize="14px"
                    fontWeight="500"
                    onClick={() => updateValue({ correosActivos: true })}
                >
                    Activar correos
                </Button>
            )}
        </>
    );
} 