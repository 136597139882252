import axios, { AxiosResponse, AxiosError, AxiosInstance } from "axios";

export type ClientInt = "admin" | "supervisor" | undefined

const _axios: AxiosInstance = axios.create({
    baseURL: `${import.meta.env.VITE_URL_CAMPUS_API}`,
})

_axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('tokenPanel');

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        config.headers["x-client-full-url"] = window.location.href;

        return config;
    },

    (error) => {
        Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => Promise.reject(error),
);

export default _axios