import { Flex, Text } from "@chakra-ui/react";
import { CorreccionesTable } from "./Correcciones/Table/CorreccionesTable";
import { MensajesTable } from "./Mensajes/Table/MensajesTable";
import { BiChat, BiDetail, BiEnvelope } from "react-icons/bi";
import { MdOutlineLabelImportant } from "react-icons/md";
import { AtencionTable } from "./Atencion/Table/AtencionTable";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../shared/context/auth.context";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { useModulos } from "../../../shared/hooks/ModuloFundae";
import { ForoTable } from "./Foro/Table/ForoTable";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const TabsSeguimiento = () => {
    const { moduloFUNDAE } = useModulos();
    const { user } = useAuthContex();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/seguimiento/correcciones`,
            `/seguimiento/mensajes`,
            `/seguimiento/atencion`,
            `/seguimiento/foro`,
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("correcciones");
        }
    }, [location.pathname]);

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            path: "correcciones",
            label: "Correcciones pendientes",
            icon: BiChat,
            content: <CorreccionesTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Mensajes pendientes",
            path: "mensajes",
            icon: BiEnvelope,
            content: <MensajesTable />
        },
        {
            isAllowed: moduloFUNDAE,
            path: "atencion",
            label: "Requieren atención",
            icon: MdOutlineLabelImportant,
            content: <AtencionTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            path: "foro",
            label: "Dudas foro",
            icon: BiDetail,
            content: <ForoTable />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Pendientes
                </Text>
            }
        >
            <Flex
                direction="column"
                w="100%"
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <TabsRouter
                        tabs={tabs}
                    />
                </Flex>
            </Flex>
        </Topbar>
    )
};