import { Box, Button, Flex, FormLabel, Spinner, Text, Tooltip, useToast } from "@chakra-ui/react";
import { CursosInt } from "../../../../../interfaces/CursosInt";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { updateCurso } from "../../../../../shared/middlewares/cursos.middleware";
import { useClient } from "../../../../../shared/context/client.context";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import ReactQuill from "react-quill";
import { SkeletonGuiaDidactica } from "../../../components/Skeletons/CursosGuiaDidacticaSkeleton";

interface Props {
    curso: CursosInt | undefined;
    loadingCurso: boolean
    Refresh: () => void;
    updateValue: (value: any) => void;
}

export const TabGuiaDidactica = ({ curso, Refresh, loadingCurso, updateValue }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getInfoGuia = (type: "datos" | "dirigido" | "requisitos" | "objetivos") => {
        if (!curso?.infoGuia) return undefined;

        if (Reflect.has(curso?.infoGuia, type)) return curso?.infoGuia[type]
        else return undefined;
    }

    const initialValues = {
        datos: getInfoGuia("datos"),
        dirigido: getInfoGuia("dirigido"),
        requisitos: getInfoGuia("requisitos"),
        objetivos: getInfoGuia("objetivos"),
    };

    const validationSchema = Yup.object({
        datos: Yup.string()
            .typeError("Este campo es requerido"),
        dirigido: Yup.string()
            .typeError("Este campo es requerido"),
        requisitos: Yup.string()
            .typeError("Este campo es requerido"),
        objetivos: Yup.string()
            .typeError("Este campo es requerido"),
    });

    const handleSubmit = async (values: any) => {
        if (!curso?.id) {
            toastNotify(toast, StatusEnumTypes.ERROR, "No se encontró el ID del curso");
            return;
        }

        setIsLoading(true);

        try {
            const guiaDidactica = {
                datos: values.datos || '',
                dirigido: values.dirigido || '',
                requisitos: values.requisitos || '',
                objetivos: values.objetivos || '',
            };

            await updateCurso({
                id: curso.id,
                data: {
                    infoGuia: guiaDidactica
                },
                client: client
            });

            await Refresh();
            toastNotify(toast, StatusEnumTypes.SUCCESS, "Guía didáctica actualizada correctamente");
        } catch (error) {
            toastNotify(toast, StatusEnumTypes.ERROR, "Error al actualizar la guía didáctica");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        loadingCurso ?
            <SkeletonGuiaDidactica />
            :
            <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex direction="column" gap="0px" className='information-texteditor'>
                                <Flex
                                    gap="20px"
                                    direction="row-reverse"
                                    pb="20px"
                                >
                                    <Flex
                                        flex="1"
                                        direction="column"
                                    >
                                        <InformationBox
                                            noHeader
                                        >
                                            <Box
                                                p="20px"
                                            >
                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Datos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.datos}
                                                        onChange={(content: string) => {
                                                            formik.setFieldValue('datos', content);
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Requisitos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.requisitos}
                                                        onChange={(content: string) => {
                                                            formik.setFieldValue('requisitos', content);
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        ¿A quién va dirigido?
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.dirigido}
                                                        onChange={(content: string) => {
                                                            formik.setFieldValue('dirigido', content);
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Objetivos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.objetivos}
                                                        onChange={(content: string) => {
                                                            formik.setFieldValue('objetivos', content);
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>
                                            </Box>
                                        </InformationBox>

                                        <Flex
                                            w="100%"
                                            justifyContent="center"
                                        >
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                h="fit-content"
                                                w="fit-content"
                                                bg="main"
                                                color="purewhite"
                                                p="10px 20px"
                                                borderRadius="5px"
                                                fontSize="16x"
                                                fontWeight="700"
                                                _hover={{ bg: "light_grey", color: "black" }}
                                            >
                                                Guardar cambios
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
    )
}


const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ["code-block"],
    ["image"],
];

const modules = {
    toolbar: toolbarOptions,
    clipboard: {
        matchVisual: false,
    },
};

const formats = [
    "header",
    "image",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
];