import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const updatePlantilla = async ({
    id,
    data,
    client
}: {
    id: string;
    data: {
        nombre?: string;
        contenido?: string;
        puck?: object;
    },
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/plantillas/${id}`,
        data,
        {}
    )
};

export const addPlantilla = async ({
    plantilla,
    client
}: {
    plantilla: {
        nombre: string;
        contenido: string;
        puck?: object;
        tipo: "mjml" | "html";
        categoria: string;
    },
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/plantillas`,
        plantilla,
        {}
    )
};

export const MJMLtoHTML = async ({ mjml, client }: { mjml: string, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/mjml2html`,
        {
            mjml: mjml,
        },
        {}
    )
}

export const sendTestPlantilla = async ({
    client,
    data
}: {
    client: ClientInt,
    data: {
        userId: string;
        matriculaId: string;
        grupoId: string;
        plantilla: string;
        email: string;
        datos: {
            porcentaje: number
        };
    }
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/plantillas/testPlantilla`,
        data,
        {}
    )
};

export const testDiplomaPlantilla = async ({
    client,
    data
}: {
    client: ClientInt,
    data: {
        plantillaId: string;
        matriculaId: string;
    }
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/plantillas/testPlantillaPDF`,
        data,
        {}
    )
}

export const deletePlantilla = async ({
    id,
    client
}: {
    id: string;
    client: ClientInt;
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(
        `${tenant}/${client}/plantillas/${id}`,
        {}
    )
}