import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Box, Grid, Flex, Text, UnorderedList, ListItem, ModalHeader, useToast, Textarea } from "@chakra-ui/react";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { CodeiumEditor } from "@codeium/react-code-editor";
import { ChangeEvent, useState } from "react";
import { corregirLeccion } from "../../../../shared/middlewares/lecciones.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";

export enum LenguajesEnum {
    JavaScript = "javascript",
    Python = "python",
    HTML = "html",
    CSS = "css",
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt;
}

export const ModalAutocorrecion = ({
    isOpen,
    onClose,
    leccion,
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const [lenguaje, setLenguaje] = useState<LenguajesEnum>(LenguajesEnum.JavaScript);
    const [respuesta, setRespuesta] = useState<string>(
        leccion?.AIConfig?.tipo === "texto" ? "" : "console.log('Hello, world!');"
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [correccion, setCorreccion] = useState<{
        evaluacion: string[],
        puntuacion: number,
    }>();

    const handleCorregir = async () => {
        if (!leccion?.contenido) return toastNotify(toast, StatusEnumTypes.ERROR, "La lección no tiene contenido");
        if (!leccion?.AIConfig?.tipo) return toastNotify(toast, StatusEnumTypes.ERROR, "La lección no tiene configuración de AI");

        setLoading(true);

        corregirLeccion({
            client: client,
            type: leccion?.AIConfig?.tipo as "texto" | "codigo",
            data: {
                pregunta: leccion?.contenido,
                respuesta: respuesta,
                guiaCorreccion: leccion?.AIConfig?.correccion || "",
                dificultad: "Fácil"
            }
        })
            .then((response) => setCorreccion(response?.data))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="6xl"
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                maxH="95vh"
                rounded="20px"
                p="40px"
            >
                <ModalHeader
                    fontSize="20px"
                    fontWeight="600"
                    color="secondary"
                >
                    Autocorrección
                </ModalHeader>

                <Box
                    m="10px"
                    overflowY="auto"
                    overflowX="hidden"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <ModalBody>
                        {leccion?.AIConfig?.tipo === "codigo" &&
                            <>
                                <Flex gap="10px">
                                    {Object.values(LenguajesEnum).map((lang) => (
                                        <Button
                                            key={lang}
                                            onClick={() => setLenguaje(lang)}
                                            bg={lenguaje === lang ? "#1D1D1D" : "light_grey"}
                                            color={lenguaje === lang ? "purewhite" : "#1D1D1D"}
                                            _hover={lenguaje !== lang ? {
                                                bg: "gray.300"
                                            } : {}}
                                            h="fit-content"
                                            w="fit-content"
                                            p="5px 20px"
                                            rounded="0"
                                            fontSize="16px"
                                            fontWeight="400"
                                            pb="10px"
                                        >
                                            {lang}
                                        </Button>
                                    ))}
                                </Flex>

                                <CodeiumEditor
                                    language={lenguaje}
                                    theme="vs-dark"
                                    onChange={(code: string | undefined) => setRespuesta(code || "")}
                                    value={respuesta}
                                />
                            </>
                        }

                        {leccion?.AIConfig?.tipo === "texto" &&
                            <Textarea
                                value={respuesta}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setRespuesta(e.target.value)}
                                minHeight="300px"
                                resize="none"
                            />
                        }

                        <Flex
                            display={correccion ? "flex" : "none"}
                            direction="column"
                            gap="30px"
                            mt="40px"
                            p="30px"
                            borderRadius="15px"
                        >
                            <Box>
                                <Text
                                    fontSize="24px"
                                    fontWeight="600"
                                    color="secondary"
                                    mb="20px"
                                >
                                    Corrección
                                </Text>

                                <UnorderedList
                                    spacing={3}
                                    fontSize="16px"
                                    pl="20px"
                                >
                                    {correccion?.evaluacion?.map((evaluacion: string, index: number) => (
                                        <ListItem key={index}>
                                            {evaluacion}
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </Box>

                            <Flex
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                bg="white"
                                p="25px"
                                borderRadius="10px"
                            >
                                <Text
                                    fontSize="20px"
                                    fontWeight="600"
                                    color="secondary"
                                    mb="10px"
                                >
                                    Puntuación obtenida
                                </Text>

                                <Text
                                    fontSize="36px"
                                    fontWeight="700"
                                    color={correccion?.puntuacion && correccion?.puntuacion < 75 ? "red.500" : "green.500"}
                                >
                                    {correccion?.puntuacion}%
                                </Text>
                            </Flex>
                        </Flex>
                    </ModalBody>

                    <ModalFooter
                        display="flex"
                        gap="20px"
                    >
                        <Button
                            bg="transparent"
                            border="1px solid"
                            borderColor="secondary"
                            p="10px 25px"
                            h="fit-content"
                            rounded="5"
                            color="secondary"
                            fontSize="18px"
                            fontWeight="500"
                            onClick={() => {
                                onClose();
                                setRespuesta(leccion?.AIConfig?.tipo === "texto" ? "" : "console.log('Hello, world!');");
                                setLenguaje(LenguajesEnum.JavaScript);
                                setCorreccion(undefined);
                            }}
                            _hover={{ color: "purewhite", backgroundColor: "secondary" }}
                        >
                            Salir
                        </Button>

                        <Button
                            bg="secondary"
                            p="10px 25px"
                            border="1px solid"
                            borderColor="secondary"
                            h="fit-content"
                            rounded="5"
                            color="purewhite"
                            fontSize="18px"
                            fontWeight="500"
                            onClick={handleCorregir}
                            _hover={{ color: "secondary", backgroundColor: "transparent" }}
                            isLoading={loading}
                        >
                            Corregir
                        </Button>
                    </ModalFooter>
                </Box>
            </ModalContent>
        </Modal>
    )
}