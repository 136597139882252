export enum LeccionTipoEnum {
    FICHERO = 'fichero',
    EMBEBIDO = 'embebido',
    TEXTO = 'texto',
    ENTREGABLE = 'entregable',
    URL = 'url',
    SESION_VIVO = 'sesion_vivo',
    AUTOCORREGIBLE = 'autocorregible'
}


export enum ComponenteLeccionEnum {
    VIDEO = 'video',
    PDF_VIEWER = 'pdf_viewer',
    MARKDOWN_PREVIEW = 'markdown_preview',
    IFRAME = 'iframe',
    LINK = 'link',
    IMAGEN = 'imagen',
    SESION_VIVO = 'sesion_vivo'
}


export enum SubtipoLeccion {
    FICHERO = 'fichero',
    EMBEBIDO = 'embebido',
    TEXTO = 'texto',
}