import { Box, Flex, Text, Icon, Button, useToast, useDisclosure } from "@chakra-ui/react";
import { GruposInt } from "../../../../../../interfaces/GruposInt";
import { InformationBox } from "../../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { useEffect, useRef, useState } from "react";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { BiInfoCircle, BiLock, BiMailSend, BiPencil, BiX } from "react-icons/bi";
import { CustomAvatar } from "../../../../../../shared/components/Avatar/CustomAvatar";
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { capitalizeFirst } from "../../../../../../shared/utils/functions/capitalizeFirst";
import { CustomSwitch } from "../../../../../../shared/components/CustomElements/CustomSwitch";
import { updateGrupo } from "../../../../../../shared/middlewares/grupos.moddleware";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { useClient } from "../../../../../../shared/context/client.context";
import { handleErrors } from "../../../../../../shared/utils/functions/handleErrors";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { ModalGestion } from "../../../../components/Modals/ModalGestion";
import { CopyTooltip } from "../../../../../../shared/components/CustomElements/CopyTooltip";
import { CustomButton } from "../../../../../../shared/components/CustomElements/CustomButton";
import { GuiaInicioModal } from "../../../../components/Modals/GuiaIncioModal";
import { ModalAlert } from "../../../../../../shared/components/Modals/ModalAlert";
import { getKey } from "../../../../../../shared/middlewares/config.middleware";
import { useData } from "../../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { useUserPolicy } from "../../../../../../shared/hooks/PermissionPolicy";

interface Props {
    grupo: GruposInt | undefined;
    updateValue: (value: any) => void;
    Refresh: () => void;
}

export const TabFundae = ({ grupo, updateValue, Refresh }: Props) => {
    const { user } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const { gestion, contenido } = useUserPolicy();
    const { isOpen: isOpenGestion, onOpen: onOpenGestion, onClose: onCloseGestion } = useDisclosure();
    const { isOpen: isOpenGuiaInicio, onOpen: onOpenGuiaInicio, onClose: onCloseGuiaInicio } = useDisclosure();
    const [userURL, setUserURL] = useState<string>()
    const [editIdgrupo, setEditIdGrupo] = useState<boolean>(false)
    const [editAccion, setEditAccion] = useState<boolean>(false)
    const [editCodigoGrupo, setEditCodigoGrupo] = useState<boolean>(false)
    const [editExpediente, setEditExpediente] = useState<boolean>(false)
    const [requisitos, setRequisitos] = useState<"completos" | "incompletos">("completos")
    const [errores, setErrores] = useState<any>([])
    const [gestionaEmpresa, setGestionaEmpresa] = useState<boolean>(false)
    const { isOpen: isOpenControlUpdate, onOpen: onOpenControlUpdate, onClose: onCloseControlUpdate } = useDisclosure()
    const changeRef = useRef<Record<"accionFormativa" | "codigoGrupo", string> | null>(null)
    // const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    // const [faltanDatos, setFaltanDatos] = useState<string[]>([])

    const [isUniqueEmpresa, setIsUniqueEmpresa] = useState<boolean>(true)
    const [empresaDefectoId, setEmpresaDefectoId] = useState<string | null>(null)
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        if (empresas && empresas?.data?.length > 0) {
            setIsUniqueEmpresa(empresas?.meta?.total === 1)

            if (empresas?.meta?.total === 1) {
                setEmpresaDefectoId(empresas?.data[0]?.id)
            }
        }
    }, [empresas])

    useEffect(() => {
        !grupo?.gestionaEmpresa
            ? setGestionaEmpresa(false)
            : setGestionaEmpresa(true)
    }, [grupo])

    useEffect(() => {
        getKey("userURL", client)
            .then((response) => setUserURL(response?.data?.data))
    }, [])

    useEffect(() => {
        const validateRequisitos: string[] = [];

        if (!grupo?.idFundae) validateRequisitos.push("ID FUNDAE")
        if (!grupo?.accionFormativa) validateRequisitos.push("código acción")
        if (!grupo?.numeroExpediente) validateRequisitos.push("nº Expediente")
        if (!grupo?.codigoGrupo) validateRequisitos.push("codigo grupo")
        if (grupo?.tutores?.length == 0) validateRequisitos.push("docentes")
        if (!grupo?.inspectorSepe) validateRequisitos.push("inspector asignado")

        if (validateRequisitos?.length == 0) setRequisitos("completos")
        if (validateRequisitos?.length > 0) setRequisitos("incompletos")

        setErrores(validateRequisitos)
    }, [grupo])

    const updateGestion = () => {
        if (
            !grupo?.id ||
            gestionaEmpresa === null ||
            gestionaEmpresa === undefined
        ) return

        let newValues = {}

        if (gestionaEmpresa && !isUniqueEmpresa) {
            onOpenGestion()
            return
        } else if (gestionaEmpresa && isUniqueEmpresa) {
            newValues = {
                fundae: true,
                gestionaEmpresa: false,
                empresaOrganizadoraId: empresaDefectoId
            }

            setGestionaEmpresa(false)
        } else {
            newValues = {
                fundae: true,
                gestionaEmpresa: true,
                empresaOrganizadoraId: null
            }

            setGestionaEmpresa(true)
        }

        updateGrupo({
            id: grupo?.id,
            data: newValues,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, `Gestion FUNDAE se ha actualizado correctamente`)

                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const sendGuiaInicio = () => {
        if (isOpenGuiaInicio) onCloseGuiaInicio()
        onOpenGuiaInicio()
    }

    const controlUpdate = async (values: Record<"accionFormativa" | "codigoGrupo", string>) => {
        changeRef.current = values

        if (isOpenControlUpdate) onCloseControlUpdate()
        onOpenControlUpdate()

        return updateValue(values)
    }

    const onConfirm = async () => {
        onCloseControlUpdate()

        if (grupo?.fundae && (changeRef.current?.accionFormativa || changeRef.current?.codigoGrupo)) {
            const nombre = `AF${changeRef.current?.accionFormativa ?? grupo?.accionFormativa}G${changeRef.current?.codigoGrupo ?? grupo?.codigoGrupo}`

            updateValue({
                nombre: nombre
            })
        }

        changeRef.current = null
        return
    }

    // const downloadXMLFUNDAE = () => {
    //     if (!grupo?.id) return;

    //     setLoadingDownload(true)

    //     downloadXML({
    //         data: {
    //             tipo: TipoXML.Grupo,
    //             grupoId: grupo?.id
    //         },
    //         client: client
    //     })
    //         .then((response) => {
    //             const url = window.URL.createObjectURL(new Blob([response?.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', `grupo-${grupo?.nombre}.xml`);
    //             document.body.appendChild(link);
    //             link.click();
    //             link.parentNode?.removeChild(link);
    //         })
    //         .catch((error: any) => {
    //             const errors = handleErrors(
    //                 error?.response?.data?.errors,
    //                 error?.response?.status
    //             )

    //             errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
    //         })
    //         .finally(() => setLoadingDownload(false))
    // }

    // useEffect(() => {
    //     if (grupo?.empresaOrganizadoraId) {
    //         checkXML({
    //             empresaId: grupo?.empresaOrganizadoraId,
    //             client: client
    //         })
    //             .then((response) => {
    //                 setFaltanDatos(response?.data?.data?.falta)
    //             })
    //     }
    // }, [grupo])

    return (
        <Flex gap="20px">
            <Flex direction="column" flex="1">
                <InformationBox title="Configuración del grupo">
                    <Box>
                        <Flex
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="10px 20px"
                            alignItems="center"
                            justifyContent="space-between"
                            onMouseEnter={() => setEditIdGrupo(true)}
                            onMouseLeave={() => setEditIdGrupo(false)}
                            gap="10px"
                        >
                            <InformationInput
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                isRequired={true}
                                name="idFundae"
                                label="id grupo"
                                defaultValue={grupo?.idFundae || ""}
                                updateValue={updateValue}
                                isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                            />

                            <Flex boxSize="20px">
                                {editIdgrupo && <Icon as={BiPencil} boxSize="20px" />}
                            </Flex>
                        </Flex>

                        <Flex
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="10px 20px"
                            alignItems="center"
                            justifyContent="space-between"
                            onMouseEnter={() => setEditAccion(true)}
                            onMouseLeave={() => setEditAccion(false)}
                            gap="10px"
                        >
                            <InformationInput
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                isRequired={true}
                                name="accionFormativa"
                                label="código de acción"
                                defaultValue={grupo?.accionFormativa || ""}
                                updateValue={controlUpdate}
                                isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                            />

                            <Flex boxSize="20px">
                                {editAccion && <Icon as={BiPencil} boxSize="20px" />}
                            </Flex>
                        </Flex>

                        <Flex
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="10px 20px"
                            alignItems="center"
                            justifyContent="space-between"
                            onMouseEnter={() => setEditCodigoGrupo(true)}
                            onMouseLeave={() => setEditCodigoGrupo(false)}
                            gap="10px"
                        >
                            <InformationInput
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                isRequired={true}
                                name="codigoGrupo"
                                label="código grupo"
                                defaultValue={grupo?.codigoGrupo || ""}
                                updateValue={controlUpdate}
                                isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                            />

                            <Flex boxSize="20px">
                                {editCodigoGrupo && <Icon as={BiPencil} boxSize="20px" />}
                            </Flex>
                        </Flex>

                        <Flex
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="20px"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="10px"
                        >
                            <InformationInput
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                name="nombre"
                                label="Denominación"
                                defaultValue={grupo?.curso?.nombre || ""}
                                updateValue={updateValue}
                                isDisabled={true}
                            />

                            <Icon as={BiLock} boxSize="20px" />
                        </Flex>

                        <Flex
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="10px 20px"
                            alignItems="center"
                            justifyContent="space-between"
                            onMouseEnter={() => setEditCodigoGrupo(true)}
                            onMouseLeave={() => setEditCodigoGrupo(false)}
                            gap="10px"
                        >
                            <InformationInput
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                isRequired={true}
                                name="modalidad"
                                label="modalidad"
                                defaultValue={grupo?.modalidad === "teleformacion" ? "Teleformación" : capitalizeFirst(grupo?.modalidad)}
                                isDisabled={true}
                                updateValue={updateValue}
                            />

                            <Flex boxSize="20px">
                                <Icon as={BiLock} boxSize="20px" />
                            </Flex>
                        </Flex>
                    </Box>
                </InformationBox>

                {!grupo?.gestionaEmpresa && grupo?.gestionaEmpresa !== null ?
                    <InformationBox title="Empresa Interna">
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="15px"
                            >
                                <Flex gap="20px" w="100%">
                                    <Text
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="700"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                        flex="1"
                                    >
                                        nombre empresa
                                    </Text>

                                    <Text
                                        color="dark_blue"
                                        fontSize="14px"
                                        fontWeight="400"
                                        flex="1"
                                        pl="5%"
                                        cursor="not-allowed"
                                    >
                                        {grupo?.empresaOrganizadora?.nombre}
                                    </Text>
                                </Flex>

                                <Icon as={BiLock} boxSize="20px" />
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <Flex gap="20px" flex="1">
                                    <Text
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="700"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                        flex="1"
                                    >
                                        Nº de expediente
                                    </Text>

                                    <Flex
                                        gap="10px"
                                        flex="1"
                                        alignItems="center"
                                    >
                                        <CopyTooltip
                                            idName="expedienteEmpresa"
                                        >
                                            <Text
                                                id="expedienteEmpresa"
                                                color="dark_blue"
                                                fontSize="14px"
                                                fontWeight="400"
                                                cursor="copy"
                                            >
                                                {grupo?.numeroExpediente}
                                            </Text>
                                        </CopyTooltip>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <Flex gap="20px" w="100%">
                                    <Text
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="700"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                        flex="1"
                                    >
                                        CIF empresa *
                                    </Text>

                                    <Text
                                        color="dark_blue"
                                        fontSize="14px"
                                        fontWeight="400"
                                        flex="1"
                                        pl="4%"
                                        cursor="not-allowed"
                                    >
                                        {grupo?.empresaOrganizadora?.cif || ""}
                                    </Text>
                                </Flex>

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                pr="5%"
                                alignItems="center"
                            >
                                <CustomSwitch
                                    label="Gestión FUNDAE"
                                    value={!gestionaEmpresa}
                                    setValue={setGestionaEmpresa}
                                    optionLeft="Interna"
                                    optionRight="Externa"
                                    onClick={updateGestion}
                                />
                            </Flex>
                        </Box>
                    </InformationBox>
                    :
                    <InformationBox
                        title="Empresa externa"
                        component={
                            <CustomButton
                                button={{
                                    label: "Enviar inicio FUNDAE",
                                    onClick: () => sendGuiaInicio(),
                                    isDisabled: !grupo?.empresas || grupo?.empresas?.length === 0,
                                    leftIcon: {
                                        as: BiMailSend,
                                        boxSize: "20px"
                                    }
                                }}
                                tooltip={{
                                    label: "El grupo debe tener una empresa asociada para enviar la guía de inicio",
                                    isDisabled: !grupo?.empresas || grupo?.empresas?.length === 0 ? false : true,
                                }}
                            />
                        }
                    >
                        <Box>
                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                onMouseEnter={() => setEditExpediente(true)}
                                onMouseLeave={() => setEditExpediente(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    isRequired={true}
                                    name="numeroExpediente"
                                    label="nº de expediente"
                                    defaultValue={grupo?.numeroExpediente || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editExpediente && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                pr="5%"
                                alignItems="center"
                            >
                                <CustomSwitch
                                    label="Gestión FUNDAE"
                                    value={!gestionaEmpresa}
                                    setValue={setGestionaEmpresa}
                                    optionLeft="Interna"
                                    optionRight="Externa"
                                    onClick={updateGestion}
                                />
                            </Flex>
                        </Box>
                    </InformationBox>
                }
            </Flex>

            <Flex direction="column" flex="1">
                <InformationBox
                    title="Datos acceso del inspector"
                    extraInfo="Funcionamiento del inspector"
                >
                    <Box>
                        <Flex
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="20px"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="10px"
                        >
                            <Flex gap="20px" w="100%">
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="700"
                                    letterSpacing="0.24px"
                                    textTransform="uppercase"
                                    flex="1"
                                >
                                    URL
                                </Text>

                                <CopyTooltip
                                    idName="urlInspector"
                                    styles={{
                                        flex: "1",
                                        pl: "5%"
                                    }}
                                >
                                    <Text
                                        id="urlInspector"
                                        color="dark_blue"
                                        fontSize="14px"
                                        fontWeight="400"
                                        cursor="copy"
                                        whiteSpace={"nowrap"}
                                    >

                                        {userURL}
                                    </Text>
                                </CopyTooltip>
                            </Flex>
                        </Flex>

                        {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                            <Flex
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"

                            >
                                <Flex gap="50px" w="100%">
                                    <Text
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="700"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                        flex="1"
                                    >
                                        inspector asignado
                                    </Text>

                                    {grupo?.inspectorSepe ?
                                        <Flex
                                            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                            borderRadius="9px"
                                            p="10px 15px"
                                            bg="purewhite"
                                            gap="10px"
                                            alignItems="center"
                                            flex="1"
                                        >
                                            <CustomAvatar
                                                name={grupo?.inspectorSepe?.username + "fundae " || ""}
                                                size="30px"
                                                src={grupo?.inspectorSepe?.avatar?.url}
                                            />

                                            <Flex direction="column">
                                                <Flex gap="10px">
                                                    <Text fontWeight="600">User:</Text>
                                                    <CopyTooltip
                                                        idName="usernameInspector"
                                                    >
                                                        <Text
                                                            id="usernameInspector"
                                                            cursor="copy"
                                                        >
                                                            {grupo?.inspectorSepe?.username || ""}
                                                        </Text>
                                                    </CopyTooltip>
                                                </Flex>

                                                <Flex gap="10px">
                                                    <Text fontWeight="600">clave:</Text>
                                                    <CopyTooltip
                                                        idName="claveInspectorSepe"
                                                    >
                                                        <Text
                                                            id="claveInspectorSepe"
                                                            cursor="copy"
                                                        >
                                                            {grupo?.inspectorSepe?.claveInspectorSepe || ""}
                                                        </Text>
                                                    </CopyTooltip>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        :
                                        (
                                            grupo?.curso?.inspectores &&
                                            grupo?.curso?.inspectores?.find((i: any) => i?.meta?.pivot_empresa_id === grupo?.empresaOrganizadora?.id) &&
                                            grupo?.curso?.inspectores?.find((i: any) => i?.meta?.pivot_empresa_id === grupo?.empresaOrganizadora?.id).length !== 0
                                        ) ?
                                            <Flex flex="1">
                                                <Button
                                                    h="fit-content"
                                                    w="fit-content"
                                                    bg="black"
                                                    color="#FFF"
                                                    p="5px 10px"
                                                    borderRadius="5px"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                    _hover={{ bg: "light_grey", color: "black" }}
                                                    onClick={() => {
                                                        const inspector = grupo?.curso?.inspectores?.find((i: any) => i?.meta?.pivot_empresa_id === grupo?.empresaOrganizadora?.id)

                                                        if (!inspector) return
                                                        updateValue({ inspectorSepeId: inspector?.id })
                                                    }}
                                                >
                                                    Copiar inspector del curso
                                                </Button>
                                            </Flex>
                                            :
                                            <Text
                                                flex="1"
                                                pr="20px"
                                                color="fail"
                                                fontSize="14px"
                                                fontWeight="400"
                                            >
                                                Debe asignar un inspector al curso asociado
                                            </Text>
                                    }
                                </Flex>
                            </Flex>
                        }
                    </Box>
                </InformationBox>

                <Box
                    w="100%"
                    bg="purewhite"
                    rounded="10px"
                    border="1px"
                    borderColor="light_grey"
                >
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        p="15px 20px"
                        roundedTop={requisitos === "completos" ? "0" : "10px"}
                        rounded={requisitos === "completos" ? "10px" : 0}

                    >
                        <Text
                            color="main"
                            fontSize="18px"
                            fontWeight="500"
                        >
                            Requisitos de FUNDAE
                        </Text>

                        <Flex
                            gap="5px"
                            p="5px 10px"
                            borderRadius="5px"
                            bg={requisitos === "completos" ? "#ADFFB0" : "fail_bg"}
                            color={requisitos === "completos" ? "dark_:blue" : "#740000"}
                            alignItems="center"
                        >
                            <Icon
                                as={BiInfoCircle}
                                boxSize="15px"
                                color={requisitos === "completos" ? "dark_:blue" : "fail_main"} />

                            <Text
                                color="font"
                                fontSize="12px"
                                fontWeight="600"
                                letterSpacing="0.24px"
                                textTransform="uppercase"
                            >
                                {requisitos}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex direction="column">
                        {(errores && errores?.length > 0)
                            && errores?.map((error: any, index: number) => (
                                <Flex
                                    key={index}
                                    p="20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    borderBottom={(errores?.length - 1) === (index) ? "none" : "1px solid"}
                                    borderColor="light_grey"
                                >
                                    <Flex flex="1" alignItems="center">
                                        <Text
                                            flex="1"
                                            color="font"
                                            fontSize="12px"
                                            fontWeight="700"
                                            letterSpacing="0.24px"
                                            textTransform="uppercase"
                                        >
                                            {error}
                                        </Text>

                                        <Text
                                            flex="1"
                                            color="dark_blue"
                                            fontSize="14px"
                                            fontWeight="400"
                                        >
                                            Sin Asignar
                                        </Text>
                                    </Flex>

                                    <Icon
                                        as={BiX}
                                        boxSize="20px"
                                        bg="fail_main"
                                        p="2px"
                                        rounded="40px"
                                        color="purewhite" />
                                </Flex>
                            ))
                        }
                        {/*
                            <Flex
                                px="20px"
                                pb="20px"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Tooltip
                                    bg={"pureblack"}
                                    px="10px"
                                    py="5px"
                                    rounded="4px"
                                    label={
                                        <Flex
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text
                                                color="purewhite"
                                                fontSize="12px"
                                                fontWeight="500"
                                                borderBottom="1px solid"
                                                borderColor="purewhite"
                                                mb="5px"
                                            >
                                                Datos faltantes
                                            </Text>

                                            {faltanDatos?.map((dato: string) => (
                                                <Text
                                                    color="purewhite"
                                                    fontSize="12px"
                                                    fontWeight="400"
                                                >
                                                    {dato}
                                                </Text>
                                            ))}
                                        </Flex>
                                    }
                                    hasArrow
                                    placement="bottom"
                                    isDisabled={!faltanDatos || faltanDatos?.length === 0 ? true : false}
                                >
                                    <Button
                                        isDisabled={faltanDatos && faltanDatos?.length > 0 ? true : false}
                                        h="fit-content"
                                        w="fit-content"
                                        bg="main"
                                        color="purewhite"
                                        p="10px 20px"
                                        borderRadius="5px"
                                        fontSize="16x"
                                        fontWeight="700"
                                        _hover={{ bg: "light_grey", color: "black" }}
                                        isLoading={loadingDownload}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation()

                                            downloadXMLFUNDAE()
                                        }}
                                    >
                                        Descargar XML FUNDAE
                                    </Button>
                                </Tooltip>
                            </Flex>
                        */}
                    </Flex >
                </Box >
            </Flex >

            <ModalGestion
                isOpen={isOpenGestion}
                onClose={onCloseGestion}
                grupo={grupo}
                empresas={empresas}
                setGestionaEmpresa={setGestionaEmpresa}
                Refresh={Refresh}
            />

            <GuiaInicioModal
                isOpen={isOpenGuiaInicio}
                onClose={onCloseGuiaInicio}
                grupo={grupo}
            />

            <ModalAlert
                isOpen={isOpenControlUpdate}
                onClose={onCloseControlUpdate}
                title="¿Quieres que estos cambios se apliquen en el nombre del grupo?"
                onConfirm={onConfirm}
            />
        </Flex >
    );
}