import { Flex, Text, Link, Img } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ColumnProps } from "../../../../../shared/components/Table/Table"
import { UserInt } from "../../../../../interfaces/UserInt";
import { Actions } from "../../../../../shared/components/TableElements/Actions";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { IconBox } from "../../../../../shared/components/Icons/IconBox";
import { handleLinkOpen } from "../../../../../shared/utils/functions/linkOpen";

interface Props {
    onEventRemove: (event: any) => void;
}

export const EmpresasColumns = ({ onEventRemove }: Props): ColumnProps[] => {

    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    customIcon={
                        rowData?.icono?.url ?
                            <Img
                                src={rowData?.icono?.url}
                                padding="0"
                                boxSize="50px"
                            />
                            : undefined
                    }
                    label={rowData?.nombre}
                    text={rowData.cif ? "Cif: " + rowData.cif : ""}
                    link={{
                        to: `/empresas/${rowData?.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'Supervisor empresa',
            field: 'supervisor',
            key: 'supervisor',
            sortable: false,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/empresas/${rowData?.id}/perfil`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex direction="column">
                        {rowData?.users?.length > 0
                            ? rowData?.users?.map((user: UserInt, index: number) => (
                                <Text
                                    key={index}
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    {user?.nombre} {user?.apellidos}
                                </Text>
                            ))
                            :
                            <Text
                                color="fail"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                Sin supervisores asignados
                            </Text>
                        }
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Alumnos',
            field: 'totalAlumnos',
            key: 'totalAlumnos',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalAlumnos || 0) + " Alumnos"}
                    link={{
                        to: `/empresas/${rowData?.id}/perfil`,

                    }}
                />
            )
        },
        {
            header: 'Grupos activos',
            field: 'totalGruposActivos',
            key: 'totalGruposActivos',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalGruposActivos || 0) + " Grupos"}
                    link={{
                        to: `/empresas/${rowData?.id}/perfil`,

                    }}
                />
            )
        },
        {
            header: 'Grupos totales',
            field: 'totalGrupos',
            key: 'totalGrupos',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalGrupos || 0) + " Grupos"}
                    link={{
                        to: `/empresas/${rowData?.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: "",
            field: "",
            key: "",
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Eliminar empresa",
                            isDisabled: rowData?.meta?.totalGrupos > 0,
                            disableAlert: "No puedes eliminar una empresa con grupos asignados",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                    />                </Flex>
            )
        }
    ]
}