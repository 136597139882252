import { useNavigate } from "react-router-dom";
import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AlumnosColumns } from "./Columns/AlumnosColumns";
import { NewAlumnoModal } from "../../../components/Modals/NewAlumnoModal";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { getAlumnosFields } from "../../../components/Fields/Fields";
import { SpreadSheet } from "../../../../../ui/SpreadSheetTheme";
import { addAlumnosImport } from "../../../../../shared/middlewares/adjuntos.middleware";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { ModaLoading } from "../../../../../shared/components/Modals/ModalLoading";
import { useClient } from "../../../../../shared/context/client.context";
import { handleErrors, handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete";
import { deleteUser } from "../../../../../shared/middlewares/users.middleware";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../../shared/components/Table/Table";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";
import { CustomButton } from "../../../../../shared/components/CustomElements/CustomButton";
import { BiPlusCircle } from "react-icons/bi";
import { OnboardingStep } from "../../../../../shared/components/Cards/OnboardingStep";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { ConfigEnum } from "../../../../../shared/utils/Types/ConfigTypes";

export const AlumnosTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.ALUMNOS });
    const { user, logout } = useAuthContex();
    const client = useClient();
    const { contenido, total } = useUserPolicy();
    const navigate = useNavigate();
    const toast = useToast();
    const [showStepAlumnos, setShowStepAlumnos] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenCarga, onOpen: onOpenCarga, onClose: onCloseCarga } = useDisclosure();
    const { isOpen: isOpenLoading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure();
    const { data: usuarios, loading, Refresh, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.USUARIOS,
        query: query,
        ignoreRequest: query?.sortBy && query?.sortBy === "fechaEnvio" ? true : false
    });
    const alumnoRef = useRef<any>(null);
    const [hasFilter, setHasFilters] = useState<boolean>();

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const onSubmit = (data: any) => {
        onOpenLoading();

        addAlumnosImport({
            data: data?.validData,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, `Alumnos creados correctamente`)
                Refresh();
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, `Error en la carga masiva, vuelva a intentarlo`))
            .finally(() => onCloseLoading())
    }

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const onEventRemove = (event: any) => {
        alumnoRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onDelete = () => {
        deleteUser({ userId: alumnoRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Alumno eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!alumnoRef.current?.nombre || !alumnoRef.current?.apellidos) return defaultWord

        const keyword = alumnoRef.current?.nombre + " " + alumnoRef.current?.apellidos;

        return keyword
    }

    return (
        <Flex direction="column" gap="20px">
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                showBody={isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? true : false}
                filterElements={isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                    [
                        {
                            name: FiltrosNames.EMPRESA,
                            type: FiltrosTypes.ASYNC_SELECT,
                        },
                        {
                            name: FiltrosNames.TELEFONO,
                            type: FiltrosTypes.INPUT,
                        },
                        {
                            name: FiltrosNames.ESTADO_USUARIO,
                            type: FiltrosTypes.SELECT,
                        },
                        {
                            name: FiltrosNames.CORREOS_ACTIVOS,
                            type: FiltrosTypes.SELECT,
                        }
                    ] : undefined
                }
                button={
                    isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                        <Flex
                            gap="15px"
                            pos="relative"
                            zIndex={showStepAlumnos ? "99" : ""}
                        >
                            <CustomButton
                                button={{
                                    label: "Nuevo alumno",
                                    onClick: () => {
                                        onOpen();
                                        showStepAlumnos && setShowStepAlumnos(false);
                                    },
                                    isDisabled: contenido,
                                    leftIcon: {
                                        as: BiPlusCircle,
                                        boxSize: "20px"
                                    }
                                }}
                                tooltip={{
                                    label: contenido ? "No tienes permisos para realizar esta acción" : "Crear un nuevo alumno",
                                    isDisabled: false
                                }}
                            />

                            {/* TODO: Actualizar carga masiva de alumnos para descomentar la funcionalidad */}
                            {/* <CustomButton
                                button={{
                                    label: "Nueva carga masiva",
                                    onClick: () => {
                                        onOpenCarga();
                                        setShowStepAlumnos(false);
                                    },
                                    isDisabled: contenido,
                                    leftIcon: {
                                        as: BiPlusCircle,
                                        boxSize: "20px"
                                    }
                                }}
                                tooltip={{
                                    label: contenido ? "No tienes permisos para realizar esta acción" : "Realizar carga masiva para crear nuevos alumnos",
                                    isDisabled: false
                                }}
                            /> */}

                            {(showStepAlumnos && (!user?.config?.alumnos && total)) &&
                                <OnboardingStep
                                    position={{
                                        top: "15px",
                                        right: "340px"
                                    }}
                                    placement="top/right"
                                    title="Importa tus primeros alumnos"
                                    description="Gestiona la inscripción de alumnos mediante la carga masiva de datos, utilizando plantillas de importación para una integración eficiente."
                                    onClick={() => {
                                        setShowStepAlumnos(false);
                                        onOpen();
                                    }}
                                />
                            }
                        </Flex>
                        :
                        undefined
                }
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={usuarios?.data || []}
                columns={AlumnosColumns({ onEventRemove, contenido })}
                isLoading={loading}
                total={usuarios?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.usuariosFiltros : EmptyMessageType.usuarios}
            />

            <NewAlumnoModal
                isOpen={isOpen}
                onClose={onClose}
                setRefreshTable={Refresh}
                section="usuario"
            />

            <ReactSpreadsheetImport
                isOpen={isOpenCarga}
                onClose={onCloseCarga}
                onSubmit={onSubmit}
                fields={getAlumnosFields()}
                customTheme={SpreadSheet().theme}
                translations={SpreadSheet().translations}
            />

            <ModaLoading
                isOpen={isOpenLoading}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("alumno")}
            />
        </Flex>
    )
};