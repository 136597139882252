import { useClient } from '../context/client.context';
import { EndpointTypes } from '../utils/Types/EndpointTypes';
import { useDataId } from './useDataId';

export const useModulos = (): { 
    moduloFUNDAE: boolean;
    moduloAI: boolean;
} => {
    const client = useClient();

    const { data } = useDataId({
        client: client,
        endpoint: EndpointTypes.CAMPUS_CONFIG_KEY,
        id: 'moduloFUNDAE'
    })

    return {
        moduloFUNDAE: data === false ? false : true,
        moduloAI: true
    }
};