import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Flex,
    Box,
    ModalFooter,
    Button,
    useToast,
    Icon,
} from '@chakra-ui/react'
import { InformationBox } from '../../../shared/components/CustomElements/InformationBox/InformationBox';
import { InformationBoxItem } from '../../../shared/components/CustomElements/InformationBox/InformationBoxItem';
import { validateDatos } from '../../../shared/middlewares/users.middleware';
import { useClient } from '../../../shared/context/client.context';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/Types/StatusEnumTypes';
import { BsArrowLeftRight } from "react-icons/bs";
import { HorariosItem } from '../../Usuarios/components/HorariosItem';
import { DiaTypeEnum } from '../../../shared/utils/Types/HorarioLaboral';
import { useNavigate } from 'react-router-dom';
import { useModulos } from '../../../shared/hooks/ModuloFundae';
import { Table } from '../../../shared/components/Table/Table';
import { ModalColumns } from './ModalColumns';
import { EntityTypes, useQuerySession } from '../../../shared/hooks/useQuerySession';

enum DatosInt {
    NOMBRE = "nombre",
    APELLIDOS = "apellidos",
    TELEFONO = "telefono",
    LOCALIDAD = "localidad",
    DNI = "dni",
    EMAIL = "email",
    HORARIO_LABORAL = "horarioLaboral",
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    solicitud: any;
    Refresh: () => void;
}

export const DatosModal = ({
    isOpen,
    onClose,
    solicitud,
    Refresh
}: Props) => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MATRICULAS_DATOS })
    const { moduloFUNDAE } = useModulos();
    const client = useClient();
    const toast = useToast();
    const navigate = useNavigate();

    const validateCambios = (aceptado: boolean) => {
        validateDatos({
            id: solicitud?.id,
            validate: aceptado,
            client: client
        })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Se han aceptado los cambios")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => handleClose())
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => handleClose()}
            closeOnOverlayClick={false}
            size="6xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <ModalCloseButton onClick={handleClose} />

                <Box
                    m="15px"
                    overflowY="auto"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <ModalBody
                        display="flex"
                        flexDirection={"column"}
                        alignItems="center"
                        gap="20px"
                        m="40px 30px 0 30px"
                    >
                        <Flex
                            w="100%"
                            alignItems="center"
                            gap="20px"
                        >
                            <InformationBox
                                title='INFORMACIÓN ACTUAL'
                            >
                                <Box>
                                    {
                                        Object.values(DatosInt).map(
                                            (item: string, index: number) => {
                                                return showInfo({
                                                    type: 'previa',
                                                    infoPrevia: solicitud?.previo,
                                                    infoActual: solicitud?.nuevo,
                                                    item: item,
                                                    index: index
                                                })
                                            }
                                        )
                                    }
                                </Box>
                            </InformationBox>

                            <Icon
                                as={BsArrowLeftRight}
                                boxSize="22px"
                                color="font"
                            />

                            <InformationBox
                                title='INFORMACIÓN SOLICITADA'
                            >
                                <Box>
                                    {
                                        Object.values(DatosInt).map(
                                            (item: string, index: number) =>
                                                showInfo({
                                                    type: 'actual',
                                                    infoPrevia: solicitud?.previo,
                                                    infoActual: solicitud?.nuevo,
                                                    item: item,
                                                    index: index
                                                })
                                        )
                                    }
                                </Box>
                            </InformationBox>
                        </Flex>

                        <Table
                            lessWidthSize={900}
                            query={query}
                            setQuery={setQuery}
                            data={solicitud?.user?.matriculas || []}
                            columns={ModalColumns({ navigate, moduloFUNDAE })}
                            isLoading={false}
                            showFooter={false}
                            total={solicitud?.nuevo?.matriculas?.length || 0}
                        />
                    </ModalBody>

                    <ModalFooter
                        mb="20px"
                        justifyContent="center"
                        gap="40px"
                    >
                        <Button
                            fontSize="16px"
                            fontWeight="600"
                            p="10px 15px"
                            bg="black"
                            color="purewhite"
                            border="2px solid"
                            borderColor="black"
                            _hover={{ bg: "light_grey", borderColor: "light_grey", color: "black" }}
                            onClick={() => validateCambios(true)}
                        >
                            Aceptar cambios
                        </Button>

                        <Button
                            fontSize="16px"
                            fontWeight="600"
                            p="10px 15px"
                            bg="inherit"
                            color="black"
                            border="2px solid"
                            borderColor="black"
                            _hover={{ bg: "light_grey", borderColor: "light_grey" }}
                            onClick={() => validateCambios(false)}
                        >
                            Rechazar cambios
                        </Button>
                    </ModalFooter>
                </Box>
            </ModalContent>
        </Modal>
    );
}

const showInfo = ({
    type,
    infoPrevia,
    infoActual,
    item,
    index
}: {
    type: "previa" | "actual";
    infoPrevia: any;
    infoActual: any;
    item: string;
    index: number;
}) => {
    if (!infoActual) return <Box />

    if (Reflect.has(infoActual, item) && item === DatosInt.HORARIO_LABORAL)
        return (
            <Box>
                {Object.values(DiaTypeEnum).map((item: DiaTypeEnum, index: number) =>
                    <HorariosItem
                        key={index}
                        label={
                            item === DiaTypeEnum.LUNES ? "Lunes" :
                                item === DiaTypeEnum.MARTES ? "Martes" :
                                    item === DiaTypeEnum.MIERCOLES ? "Miercoles" :
                                        item === DiaTypeEnum.JUEVES ? "Jueves" :
                                            item === DiaTypeEnum.VIERNES ? "Viernes" :
                                                item === DiaTypeEnum.SABADO ? "Sabado" :
                                                    item === DiaTypeEnum.DOMINGO ? "Domingo" : ""
                        }
                        horarios={type === "actual"
                            ? infoActual?.horarioLaboral ? infoActual?.horarioLaboral[item] : []
                            : infoPrevia?.horarioLaboral ? infoPrevia?.horarioLaboral[item] : []
                        }
                    />
                )}
            </Box>
        )

    if (Reflect.has(infoActual, item) && item !== DatosInt.HORARIO_LABORAL)
        return (
            <InformationBoxItem
                border={false}
                key={index}
                label={item}
                text={
                    type === "actual"
                        ? infoActual[item] ? infoActual[item] : "--"
                        : infoPrevia[item] ? infoPrevia[item] : "--"
                }
            />
        )
}