import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    proceedWithDownload: (matriculas: any[]) => void;
    pendingDownload: any;
}

export default function ModalAvisoDiploma({ isOpen, onClose, proceedWithDownload, pendingDownload }: Props) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Advertencia</ModalHeader>
                <ModalBody>
                    Se va a generar el diploma de participantes que no han superado el curso, ¿desea continuar?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        bg="main"
                        color="purewhite"
                        onClick={() => {
                            if (pendingDownload) {
                                proceedWithDownload(pendingDownload.matriculas);
                            }
                        }}
                    >
                        Continuar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
