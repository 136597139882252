import React, { useEffect, useState } from 'react'
import { Button, Flex, useToast } from '@chakra-ui/react'
import { Filters } from '../../../../../shared/components/Filters/Filters'
import { FiltrosTypes } from '../../../../../shared/utils/Types/FiltrosEnum'
import { FiltrosNames } from '../../../../../shared/utils/Types/FiltrosEnum'
import { EntityTypes, useQuerySession } from '../../../../../shared/hooks/useQuerySession'
import { Table } from '../../../../../shared/components/Table/Table'
import { useData } from '../../../../../shared/hooks/useData'
import { EndpointTypes } from '../../../../../shared/utils/Types/EndpointTypes'
import { useClient } from '../../../../../shared/context/client.context'
import { EmptyMessageType } from '../../../../../shared/utils/Types/EmptyMessageType'
import { TipoXML } from '../../../../../shared/utils/Types/TipoXML'
import { handleDownloadXML } from '../../../../../shared/utils/functions/xmlUtils'
import { InicioColumns } from './Columns/InicioColumns'

export default function InicioTable() {
    const client = useClient();
    const toast = useToast();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.FUNDAE_INICIOS });
    const [selectedInicios, setSelectedInicios] = useState<any>(null);
    const [hasFilter, setHasFilters] = useState<boolean>();
    const [availableSelect, setAvailableSelect] = useState<boolean>(false);
    const [loadingXML, setLoadingXML] = useState<boolean>();


    const { data: grupos, loading } = useData({
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        specificQuery: {
            fundae: "true",
            gestionaEmpresa: "false",
            pendienteInicioFundae: "true",
            sortBy: "fechaInicio",
            order: "asc",
        },
        query: query
    })
    useEffect(() => {
        if (query?.empresaOrganizadora) {
            setAvailableSelect(true)
        } else {
            setSelectedInicios([])
            setAvailableSelect(false)
        }

    }, [query])


    const handleDownloadXMLClick = () => {
        if (!selectedInicios) return;

        handleDownloadXML({
            selectedItems: selectedInicios,
            tipo: TipoXML.InicioGrupo,
            client,
            fileName: 'inicios-fundae',
            toast,
            setLoading: setLoadingXML
        });
    }

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterElements={[
                    {
                        name: FiltrosNames.EMPRESA_ORGANIZADORA,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_INICIO_SUP,
                        type: FiltrosTypes.DATE,
                    }
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={grupos?.data || []}
                columns={InicioColumns({ availableSelect })}
                isLoading={loading}
                selection={selectedInicios}
                onSelectionChange={(e: any) => setSelectedInicios(e?.value)}
                total={grupos?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.gruposFiltros : EmptyMessageType.grupos}
            />

            <Button
                bg="main"
                color="purewhite"
                py="15px"
                w="250px"
                h="fit-content"
                mx="auto"
                onClick={handleDownloadXMLClick}
                isDisabled={
                    (!selectedInicios || selectedInicios?.length === 0) || !availableSelect
                }
                isLoading={loadingXML}
            >
                Descargar XML
            </Button>
        </Flex>
    )
} 