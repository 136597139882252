import { Badge, Box, Button, Flex, Icon, Progress, Text } from "@chakra-ui/react";
import { AlertsGrupo } from "../../../../../shared/components/CustomElements/AlertsGrupo";
import { EmpresasInt } from "../../../../../interfaces/EmpresasInt";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone";
import { CustomColumnProps } from "../../../../../shared/components/Table/CustomGroupTable";
import { useModulos } from "../../../../../shared/hooks/ModuloFundae";

interface Props {
    onMatriculaEvent: (rowData: any) => void;
}

export const GruposRutaColumns = ({
    onMatriculaEvent,
}: Props): CustomColumnProps[] => {
    const timeZone = useTimeZone();
    const { moduloFUNDAE } = useModulos();

    const columns: CustomColumnProps[] = [
        {
            header: 'Duracion',
            field: 'fechaInicio',
            key: 'fechaInicio',
            sortable: true,
            body: (rowData: any) => (

                <Flex direction="column" alignItems="flex-start" py="16px" mx="16px">
                    <Flex
                        alignItems="center"
                        gap="8px"
                    >
                        <Badge
                            color={
                                rowData?.estado == 2 ? "font" :
                                    rowData?.estado == 1 ? "secondary"
                                        : "success_main"
                            }
                            bg={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "variant"
                                        : "success_bg"
                            }
                            borderColor={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "variant"
                                        : "success_variant"
                            }
                            borderWidth="1px"
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="14px"
                            p="2px 5px"

                        >
                            {
                                rowData?.estado == 2 ? "Inactivo" :
                                    rowData?.estado == 1 ? "Próximo"
                                        : "Activo"
                            }
                        </Badge>

                        {rowData?.rutaId &&
                            <Badge
                                color={"warning"}
                                bg={"warning_bg"}
                                borderColor={"warning_variant"}
                                borderWidth="1px"
                                fontSize="10px"
                                fontWeight="500"
                                letterSpacing="0.2px"
                                textTransform="uppercase"
                                borderRadius="14px"
                                p="2px 5px"

                            >
                                Ruta
                            </Badge>
                        }
                    </Flex>

                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        <Text mb="2px">-</Text>
                        <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'Nombre',
            field: 'cursos.nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                >
                    <Flex direction="column">
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {rowData?.nombre}
                        </Text>
                        <Text
                            color="#7A7D81"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {rowData?.curso?.nombre}
                        </Text>
                    </Flex>

                    {(rowData?.fundae === true && moduloFUNDAE) &&
                        <Box
                            ml="auto"
                            mr="20%"
                        >
                            <AlertsGrupo grupo={rowData} />
                        </Box>
                    }
                </Flex>
            )
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            body: (rowData: any) => (
                <Flex direction="column">
                    {rowData?.empresas?.map((empresa: EmpresasInt, index: number) => (
                        <Text
                            key={index}
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {empresa?.nombre}
                        </Text>
                    ))}
                </Flex>
            )
        },
        {
            header: 'Matrículas',
            field: 'totalMatriculas',
            key: 'totalMatriculas',
            sortable: true,
            style: { width: "100px" },
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculas || 0)}
                />
            ),
        },
        {
            header: 'Progreso Matrículas', field: 'progresoMatriculas',
            key: 'progresoMatriculas',
            style: { width: "200px" },
            body: (rowData: any) => (
                <Flex gap="5px" alignItems="center" pr="10%">
                    <Progress
                        value={parseInt(rowData?.meta?.progresoMatriculas) || 0}
                        flex="1"
                        h="7px"
                        w="150px"
                        rounded="10px"
                        sx={{ '& > div': { background: "secondary" } }}
                    />
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {parseInt(rowData?.meta?.progresoLeccionesMatriculas) || 0} %
                    </Text>
                </Flex>
            )
        },
        {
            header: "",
            field: "actions",
            key: "actions",
            body: (rowData: any) => (
                <Button
                    w="fit-content"
                    h="fit-content"
                    py="8px"
                    px="15px"
                    color="purewhite"
                    bg="secondary"
                    fontSize="14px"
                    fontWeight="500"
                    _hover={{ bg: "variant" }}
                    rounded="14px"
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        onMatriculaEvent(rowData)
                    }}
                >
                    Añadir matrícula
                </Button>
            ),
        }
    ];

    const extraColumns: CustomColumnProps[] = [
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            sortable: true,
            body: (rowData: any) => (
                rowData?.fundae
                    ?
                    <Flex
                        alignItems="center"
                        gap="5px"
                        py="16px" mx="16px"
                    >
                        <Icon
                            color="#E9B418"
                            as={BsCheckCircleFill}
                            boxSize="18px"
                        />

                        <Badge
                            color={rowData?.gestionaEmpresa ? "main" : "purewhite"}
                            bg={rowData?.gestionaEmpresa ? "purewhite" : "variant"}
                            borderColor={rowData?.gestionaEmpresa ? "main" : "variant"}
                            borderWidth="1px"
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="14px"
                            p="2px 5px"

                        >
                            {rowData?.gestionaEmpresa ? "Ext" : "Int"}
                        </Badge>
                    </Flex>
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                    />
            )
        },
    ];

    return moduloFUNDAE ? [...columns.slice(0, 1), ...extraColumns, ...columns?.slice(1)] : columns
}