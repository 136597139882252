import { Flex, Text } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { EmpresasInt } from "../../../../../../interfaces/EmpresasInt";

interface Props {
    availableSelect: boolean;
}

export const InicioColumns = ({
    availableSelect
}: Props): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            selectionMode: availableSelect ? "multiple" : undefined,
        },
        {
            header: 'Nombre',
            field: 'cursos.nombre',
            key: 'nombre',
            sortable: true,
            style: { width: "200px" },
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                >
                    <Flex direction="column" ml="16px">
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {rowData?.nombre}
                        </Text>
                        <Text
                            color="#7A7D81"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {rowData?.curso?.nombre}
                        </Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            body: (rowData: any) => (
                <Flex direction="column">
                    {rowData?.empresas?.map((empresa: EmpresasInt, index: number) => (
                        <Text
                            key={index}
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                            ml="16px"
                        >
                            {empresa?.nombre ? empresa?.nombre : "--"}
                        </Text>
                    ))}
                </Flex>
            )
        },
        {
            header: 'Matrículas',
            field: 'totalMatriculas',
            key: 'totalMatriculas',
            sortable: true,
            style: { width: "100px" },
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculas || 0)}
                />
            ),
        },
        {
            header: 'Emp. Organizadora',
            field: 'empresaOrganizadora.nombre',
            key: 'empresaOrganizadora.nombre',
            body: (rowData: any) => (
                <ColumnSimpleText
                    tooltip={{ label: rowData?.empresaOrganizadora?.nombre, hasArrow: true }}
                    text={rowData?.empresaOrganizadora?.nombre || "---"}
                />
            ),
        },
        {
            header: 'Fecha Inicio',
            field: 'fechaInicio',
            key: 'fechaInicio',
            sortable: true,
            style: { width: "250px" },
            body: (rowData: any) => (
                <Text>
                    {formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}
                </Text>
            )
        },
    ]

    return columns
} 