import { Flex, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../shared/context/client.context";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { handleErrors, handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Table } from "../../../../../shared/components/Table/Table";
import { LiquidacionesColumns } from "./Colums/LiquidacionesColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete";
import { removeLiquidacion } from "../../../../../shared/middlewares/facturacion.middlewares";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";

export const LiquidacionesTable = () => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.LIQUIDACIONES });
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { data, loading, error, status, errorsList, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.LIQUIDACIONES,
        query: {
            ...query,
        },
    });

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const liquidacionRef = useRef<any>(null)

    const onEventRemove = (event: any) => {
        liquidacionRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onDelete = () => {
        removeLiquidacion({ id: liquidacionRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Liquidación eliminada correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    return (
        <Flex direction="column" gap="20px" w="100%">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterElements={[
                    {
                        name: FiltrosNames.TUTOR,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_PAGO_INICIO,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.FECHA_PAGO_FIN,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.ESTADO_LIQUIDACION,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.LIQUIDACION_EMPRESA,
                        type: FiltrosTypes.SELECT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={data?.data || []}
                columns={LiquidacionesColumns({ onEventRemove })}
                isLoading={loading}
                total={data?.meta?.total || 0}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={"liquidación"}
            />
        </Flex>
    )
}