import { LeccionInt, updateLeccionInt } from "../../interfaces/CursosInt";
import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const updateLeccion = async ({
    id,
    data,
    client
}: {
    id: string,
    data: updateLeccionInt,
    client: ClientInt
}) => {
    if (!client) return;

    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/lecciones/${id}`,
        data,
        {}
    )
};

export const addLeccion = async ({ leccion, client }: { leccion: Partial<LeccionInt>, client: ClientInt }) => {
    if (!client) return;

    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/lecciones`,
        leccion,
        {}
    )
};

export const removeLeccion = async ({ id, client }: { id: string, client: ClientInt }) => {
    if (!client) return;

    const tenant = await tenantInstance();

    return _axios.delete(
        `${tenant}/${client}/lecciones/${id}`,
    )
};

export const loadContenido = async ({
    id,
    client,
    file,
}: {
    id: string,
    client: ClientInt,
    file: File
}) => {
    if (!client) return;

    const formData = new FormData()
    formData.append('file', file);

    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/loadFileLeccion/${id}`,
        formData,
        {}
    )
}

export const corregirLeccion = async ({
    client,
    type,
    data
}: {
    client: ClientInt,
    type: "texto" | "codigo",
    data: {
        pregunta: string,
        respuesta: string,
        guiaCorreccion: string,
        dificultad: string,
    }
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    const endpoint = type === "texto" ? "corrigePregunta" : "corrigePreguntaCodigo";

    return _axios.post(
        `${tenant}/${client}/ai/${endpoint}`,
        data
    )
}