import { Badge, Flex, Text, Tooltip } from "@chakra-ui/react"

interface InfoBadgeProps {
    label: string;
    value: string | number | null | undefined;
    type?: "default" | "warning" | "success";
    tooltip?: string;
}

export const InfoBadge = ({ label, value, type = "default", tooltip }: InfoBadgeProps) => {
    if (value === null || value === undefined) return null;

    const badgeStyles = {
        default: {
            color: "font",
            bg: "light_grey",
            borderColor: "light_grey",
        },
        warning: {
            color: "warning_main",
            bg: "warning_bg",
            borderColor: "warning_variant",
        },
        success: {
            color: "success_main",
            bg: "success_bg",
            borderColor: "success_variant",
        }
    }

    const badge = (
        <Badge
            {...badgeStyles[type]}
            fontSize="10px"
            fontWeight="500"
            letterSpacing="0.2px"
            textTransform="uppercase"
            borderRadius="14px"
            borderWidth="1px"
            p="5px 8px"
        >
            {value}
        </Badge>
    );

    return (
        <Flex
            alignItems="center"
            gap="10px"
        >
            <Text
                color="font"
                fontSize="14px"
                fontWeight="600"
            >
                {label}:
            </Text>
            {tooltip ? (
                <Tooltip
                    label={tooltip}
                    placement="bottom"
                    hasArrow
                    textAlign="center"
                    px="10px"
                    py="5px"
                    rounded="8px"
                    bg="pureblack"
                    color="purewhite"
                >
                    {badge}
                </Tooltip>
            ) : badge}
        </Flex>
    );
} 