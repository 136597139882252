import { Flex, FormLabel, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { LeccionInt } from "../../../../../interfaces/CursosInt"
import { Dispatch, SetStateAction } from "react"
import DropzoneComponent from "../../../../../shared/components/Dropzone/Dropzone"
import { InformationComponent } from "../../../../../shared/components/CustomElements/InformationComponent"
import { BiInfoCircle } from "react-icons/bi"
import { LeccionTipoEnum, SubtipoLeccion } from "../../../../../shared/utils/Types/LeccionTipoEnum"
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea"
import { FormTextEditor } from "../../../../../shared/components/FormElements/FormTextEditor"
import { CustomSwitch } from "../../../../../shared/components/CustomElements/CustomSwitch"
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input"
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone"
import { FormNewTimeInput } from "../../../../../shared/components/FormElements/FormNewTimeInput"
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select"
import { formSelectStyles } from "../../../../../ui/formSelectStyles"
import { FormInput } from "@imaginagroup/bit-components.ui.form-input"

interface Props {
    formik: any;
    leccion: LeccionInt | undefined;
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
}

export const InformationLeccion = ({
    leccion,
    formik,
    file,
    setFile,
}: Props) => {
    const timeZone = useTimeZone();

    return (
        <>
            <InformationBox
                noHeader
            >
                <Flex
                    direction="column"
                    py="20px"
                    px="20px"
                >
                    {leccion?.tipo === LeccionTipoEnum.URL &&
                        <Flex
                            direction="column"
                            gap="15px"
                            mb="20px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    Contenido lección
                                </Text>

                                <InformationComponent
                                    tooltip={{
                                        content:
                                            <Flex
                                                direction="column"
                                            >
                                                <Text
                                                    textAlign="center"
                                                    fontSize="16px"
                                                    fontWeight={"600"}
                                                    mb="10px"
                                                >
                                                    Lección de tipo URL
                                                </Text>
                                                <UnorderedList
                                                    pl="10px"
                                                    display="flex"
                                                    flexDirection={"column"}
                                                    gap="10px"
                                                    fontSize="14px"
                                                    fontWeight={"400"}
                                                >
                                                    <ListItem>
                                                        Inserte la <Text as={"strong"}>URL</Text> a donde se redireccionara o descargara el recurso de la leccion.
                                                    </ListItem>

                                                    <ListItem>
                                                        Si estás obteniendo el <Text as={"strong"}>URL</Text> para una descarga de una nube como OneDrive, Google Drive, entre otras, <Text as={"strong"}>asegúrate de compartir</Text> la URL como <Text as={"strong"}>público</Text>. Esto garantizará que la lección se cargue correctamente en la aplicación.
                                                    </ListItem>
                                                </UnorderedList>
                                            </Flex>,
                                        placement: "right",
                                        hasArrow: true,
                                        isDisabled: false,
                                        styles: { rounded: "8px", p: "10px" }
                                    }}
                                    icon={BiInfoCircle}
                                />
                            </Flex>

                            <FormTextarea
                                name="contenido"
                                placeholder={"Introduce el enlace al recurso"}
                                noHead={true}
                                ui={{
                                    minHeigh: "20px",
                                    styles: {
                                        border: "1px solid",
                                        borderColor: "#E2E8F0",
                                        borderRadius: "5px",
                                        color: "font",
                                        padding: "5px",
                                        maxHeight: "100px",
                                    }
                                }}
                            />
                        </Flex>
                    }

                    {leccion?.tipo === LeccionTipoEnum.SESION_VIVO &&
                        <Flex
                            direction="column"
                            gap="15px"
                            mb="20px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    Información de la sesión
                                </Text>

                                <InformationComponent
                                    tooltip={{
                                        content:
                                            <Flex
                                                direction="column"
                                            >
                                                <Text
                                                    textAlign="center"
                                                    fontSize="16px"
                                                    fontWeight={"600"}
                                                    mb="10px"
                                                >
                                                    Lección de tipo Sesión en vivo
                                                </Text>

                                                <UnorderedList
                                                    pl="10px"
                                                    display="flex"
                                                    flexDirection={"column"}
                                                    gap="10px"
                                                    fontSize="14px"
                                                    fontWeight={"400"}
                                                >
                                                    <ListItem>
                                                        Indique si la sesión es <Text as={"strong"}>online</Text> o <Text as={"strong"}>presencial</Text>.
                                                    </ListItem>

                                                    <ListItem>
                                                        Indique una <Text as={"strong"}>fecha de inicio</Text> y una <Text as={"strong"}>fecha de fin</Text> para la sesión.
                                                    </ListItem>

                                                    <ListItem>
                                                        Si la sesión es <Text as={"strong"}>presencial</Text>, inserte la <Text as={"strong"}>dirección</Text> de la sesión.
                                                    </ListItem>

                                                    <ListItem>
                                                        Si la sesión es <Text as={"strong"}>online</Text>, inserte la <Text as={"strong"}>URL</Text> de la sesión.
                                                    </ListItem>
                                                </UnorderedList>
                                            </Flex>,
                                        placement: "right",
                                        hasArrow: true,
                                        isDisabled: false,
                                        styles: { rounded: "8px", p: "10px" }
                                    }}
                                    icon={BiInfoCircle}
                                />
                            </Flex>

                            <Flex
                                direction="column"
                            >
                                <FormLabel
                                    htmlFor="sesionVivo.online"
                                    display="flex"
                                    gap="3px"
                                    color="black"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    Tipo de sesión
                                </FormLabel>

                                <CustomSwitch
                                    value={formik?.values?.sesionVivo?.online}
                                    setValue={() => formik.setFieldValue('sesionVivo.online', !formik?.values.sesionVivo.online)}
                                    optionLeft="Online"
                                    optionRight="Presencial"
                                    height="25px"
                                    widthLabel="100%"
                                    widthSwitch="100%"
                                    padding="0"
                                />
                            </Flex>

                            <Flex
                                gap="15px"
                                alignItems="center"
                            >
                                <FormDateInput
                                    locale="es"
                                    isDateTime
                                    name="sesionVivo.fechaInicio"
                                    label="Fecha y Hora inicio"
                                    defaultValue={formatTimezone({ date: leccion?.sesionVivo?.fechaInicio, timeZone: timeZone, customFormat: "yyyy-MM-dd'T'HH:mm" })}
                                    onChange={formik.setFieldValue}
                                    placeholder="Día/Mes/Año"
                                    ui={{
                                        styleLabel: {
                                            display: "flex",
                                            gap: "3px",
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                        }
                                    }}
                                />

                                <FormNewTimeInput
                                    name="sesionVivo.fechaFin"
                                    label="Hora Fin"
                                    onChange={(time) => formik.setFieldValue('sesionVivo.fechaFin', time)}
                                    disabledTooltip="No se puede seleccionar una hora de fin si no se ha seleccionado una fecha de inicio"
                                    isDisabled={!formik?.values?.sesionVivo?.fechaInicio}
                                    time={formatTimezone({ date: leccion?.sesionVivo?.fechaFin, timeZone: timeZone, customFormat: "HH:mm" })}
                                    date={formik?.values?.sesionVivo?.fechaInicio}
                                />
                            </Flex>

                            <FormTextarea
                                name="contenido"
                                placeholder={formik?.values?.sesionVivo?.online ? "Introduce el link de la sesión en vivo" : "Introduce la ubicación de la sesión"}
                                label={formik?.values?.sesionVivo?.online ? "Link de la sesión en vivo" : "Ubicación de la sesión"}
                                ui={{
                                    minHeigh: "20px",
                                    styles: {
                                        border: "1px solid",
                                        borderColor: "#E2E8F0",
                                        borderRadius: "5px",
                                        color: "font",
                                        padding: "5px",
                                        maxHeight: "100px",
                                    },
                                    styleLabel: {
                                        display: "flex",
                                        gap: "3px",
                                        color: "black",
                                        fontSize: "14px",
                                        fontWeight: "400"
                                    }
                                }}
                            />
                        </Flex>
                    }

                    {(leccion?.tipo === LeccionTipoEnum.FICHERO ||
                        (leccion?.tipo === LeccionTipoEnum?.ENTREGABLE && leccion?.subtipo === SubtipoLeccion.FICHERO)
                    ) &&
                        <Flex
                            w="100%"
                            direction="column"
                            mb="20px"
                        >
                            <DropzoneComponent
                                file={file}
                                setFile={setFile}
                                hasContent={leccion?.contenido ? true : false}
                            />
                        </Flex>
                    }


                    {
                        (leccion?.tipo === LeccionTipoEnum.EMBEBIDO ||
                            (leccion?.tipo === LeccionTipoEnum?.ENTREGABLE && leccion?.subtipo === SubtipoLeccion.EMBEBIDO)
                        ) &&

                        <Flex
                            direction="column"
                            gap="15px"
                            pb="10px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    Contenido lección
                                </Text>

                                <InformationComponent
                                    tooltip={{
                                        content:
                                            <Flex
                                                direction="column"
                                            >
                                                <Text
                                                    textAlign="center"
                                                    fontSize="16px"
                                                    fontWeight={"600"}
                                                    mb="10px"
                                                >
                                                    Lección de tipo {leccion?.tipo === LeccionTipoEnum.ENTREGABLE ? "Entregable embebido" : "Embebido"}
                                                </Text>


                                                <UnorderedList
                                                    pl="10px"
                                                    display="flex"
                                                    flexDirection={"column"}
                                                    gap="10px"
                                                    fontSize="14px"
                                                    fontWeight={"400"}
                                                >
                                                    {leccion?.tipo === LeccionTipoEnum.ENTREGABLE ?
                                                        <>
                                                            <ListItem>
                                                                Insete la <Text as={"strong"}>URL</Text> del ejercicio a embeber.
                                                            </ListItem>

                                                            <ListItem>
                                                                Las lecciones de tipo <Text as={"strong"}>Entregable</Text> requieren una entrega en el campus, el alumno debera subir un archivo con el ejercicio resuelto donde el tutor podra calificarlo.
                                                            </ListItem>
                                                        </>
                                                        :
                                                        <ListItem>
                                                            Insete la <Text as={"strong"}>URL</Text> del contenido a embeber.
                                                        </ListItem>
                                                    }

                                                    <ListItem>
                                                        Recuerde que si está obteniendo la <Text as={"strong"}>URL</Text> de una plataforma externa como: "Vimeo", "Slides", "OneDrive", "Google Drive", entre otras. <Text as={"strong"}>Asegúrese de compartir</Text> la URL como <Text as={"strong"}>pública</Text>. Esto garantizará que el ejercicio se cargue correctamente en la aplicación.
                                                    </ListItem>
                                                </UnorderedList>
                                            </Flex>,
                                        placement: "right",
                                        hasArrow: true,
                                        isDisabled: false,
                                        styles: { rounded: "8px", p: "10px" }
                                    }}
                                    icon={BiInfoCircle}
                                />
                            </Flex>

                            <FormTextarea
                                name="contenido"
                                noHead={true}
                                ui={{
                                    minHeigh: "20px",
                                    styles: {
                                        border: "1px solid",
                                        borderColor: "#E2E8F0",
                                        borderRadius: "5px",
                                        color: "font",
                                        padding: "5px",
                                        maxHeight: "100px",
                                    }
                                }}
                                placeholder={"introduce la URL del contenido a embeber"}
                            />
                        </Flex>
                    }

                    <Flex
                        direction="column"
                        gap="20px"
                    >
                        {(
                            (leccion?.tipo === LeccionTipoEnum.ENTREGABLE || leccion?.tipo === LeccionTipoEnum.SESION_VIVO)
                            && (leccion?.AI === null || leccion?.AI === undefined)
                        ) &&
                            <FormTextarea
                                label={
                                    leccion?.tipo === LeccionTipoEnum.ENTREGABLE ?
                                        "Guía de entrega del ejercicio"
                                        :
                                        "Detalles de la sesión"
                                }
                                name="descripcion"
                                placeholder={
                                    leccion?.tipo === LeccionTipoEnum.ENTREGABLE ?
                                        "Indique al alumno como debe entregar el ejercicio"
                                        :
                                        "Indique los detalles de la sesión"
                                }
                                ui={{
                                    minHeigh: leccion?.tipo === LeccionTipoEnum.ENTREGABLE ? "80px" : "200px",
                                    styleLabel: {
                                        color: "font",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                    },
                                    styles: {
                                        border: "1px solid",
                                        borderColor: "#E2E8F0",
                                        borderRadius: "5px",
                                        color: "font",
                                        padding: "5px",
                                        maxHeight: "100px",
                                    }
                                }}
                            />
                        }

                        <CustomSwitch
                            label="Publicado"
                            isRow={false}
                            optionLeft="Si"
                            optionRight="No"
                            value={formik.values?.publicado}
                            setValue={() => formik.setFieldValue('publicado', !formik.values.publicado)}
                            colorSwitch="light_grey"
                            padding="0"
                            widthSwitch="100%"
                            labelStyles={{
                                color: "font",
                                fontSize: "14px",
                                fontWeight: "400",
                            }}
                        />
                    </Flex>
                </Flex>
            </InformationBox >


            {
                (leccion?.tipo === LeccionTipoEnum.TEXTO ||
                    (leccion?.tipo === LeccionTipoEnum?.ENTREGABLE && leccion?.subtipo === SubtipoLeccion.TEXTO)
                ) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        direction="column"
                        py="20px"
                        px="20px"
                        gap="15px"
                    >
                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Text
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                Contenido lección
                            </Text>

                            <InformationComponent
                                tooltip={{
                                    content:
                                        <Flex
                                            direction="column"
                                        >
                                            <Text
                                                textAlign="center"
                                                fontSize="16px"
                                                fontWeight={"600"}
                                                mb="10px"
                                            >
                                                Lección de tipo {leccion?.tipo === LeccionTipoEnum.ENTREGABLE ? "Entregable texto" : "Texto"}
                                            </Text>

                                            <UnorderedList
                                                pl="10px"
                                                display="flex"
                                                flexDirection={"column"}
                                                gap="10px"
                                                fontSize="14px"
                                                fontWeight={"400"}
                                                mb="10px"
                                            >
                                                <ListItem>
                                                    Insertar <Text as="strong">texto plano</Text>.
                                                </ListItem>

                                                <ListItem>
                                                    Inserta Markdown donde puedes utilizar <Text as="strong">negrita</Text>, <Text as="i">cursiva</Text>, y <Text as="u">subrayado</Text> para resaltar texto.
                                                </ListItem>

                                                <ListItem>
                                                    Insertar <Text as="strong">código</Text>.
                                                </ListItem>

                                                <ListItem>
                                                    Es posible crear listas ordenadas o desordenadas.
                                                </ListItem>

                                                <ListItem>
                                                    Puedes agregar distintos tamaños de letras y darle estilos.
                                                </ListItem>

                                                <ListItem>
                                                    Agrega enlaces en tus textos.
                                                </ListItem>
                                            </UnorderedList>

                                            <Text
                                                textAlign="center"
                                                fontSize="14px"
                                                fontWeight={"700"}
                                            >
                                                Todo esto puedes hacerlo con la barra de herramientas que se encuentra en la parte superior del editor.
                                            </Text>
                                        </Flex>,
                                    placement: "right",
                                    hasArrow: true,
                                    isDisabled: false,
                                    styles: { rounded: "8px", p: "10px" }
                                }}
                                icon={BiInfoCircle}
                            />
                        </Flex>

                        <FormTextEditor
                            name="contenido"
                            placeholder="Introduce el texto"
                            onChange={formik.setFieldValue}
                            stylesEditor={{
                                borderRadius: "10px",
                            }}
                        />
                    </Flex>
                </InformationBox>
            }

            {leccion?.AI !== null &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        direction="column"
                        py="20px"
                        px="20px"
                        gap="15px"
                    >
                        <FormSelect
                            label="Formato de corrección"
                            name="AIConfig.tipo"
                            options={[
                                { label: "Texto", value: "texto" },
                                { label: "Audio", value: "audio" },
                                { label: "Código", value: "codigo" },
                            ]}
                            ui={{
                                formSelectStyles: formSelectStyles
                            }}
                            placeholder="Seleccione el formato de corrección"
                        />

                        <FormInput
                            name="AIConfig.puntuacion"
                            label="Puntuación"
                            placeholder="Puntuación con la que se supera el ejercicio"
                            type="number"
                            min={1}
                            max={100}
                        />

                        <FormTextarea
                            name="AIConfig.pregunta"
                            label="Pregunta a corregir"
                            ui={{
                                minHeigh: "300px",
                                styles: {
                                    border: "1px solid",
                                    borderColor: "#E2E8F0",
                                    borderRadius: "5px",
                                    color: "font",
                                    padding: "5px",
                                    maxHeight: "300px",
                                }
                            }}
                            placeholder={"Pregunta que se debe tener en cuenta para a la hora de la corrección del ejercicio"}
                        />

                        <FormTextarea
                            name="AIConfig.correccion"
                            label="Guía de corrección"
                            ui={{
                                minHeigh: "300px",
                                styles: {
                                    border: "1px solid",
                                    borderColor: "#E2E8F0",
                                    borderRadius: "5px",
                                    color: "font",
                                    padding: "5px",
                                    maxHeight: "300px",
                                }
                            }}
                            placeholder={"Indica la guía de como se debe corregir el ejercicio y puntos a tener en cuenta"}
                        />
                    </Flex>
                </InformationBox>
            }
        </>
    )
}