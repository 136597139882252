import { Flex, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { HiUserGroup, HiAcademicCap, HiOutlineUserGroup, HiOutlineAcademicCap, HiOutlineUser, HiOutlineCalendar } from 'react-icons/hi'
import { CardInfo } from '../../../shared/components/Cards/CardInfo'
import { CardInfoFUNDAE } from '../../../shared/components/Cards/CardInfoFUNDAE'
import Matriculas from '../components/Matriculas'
import { Pendiente } from '../components/Pendiente'
import TopProfesores from '../components/TopProfresores'
import UltimasMatriculas from '../components/UltimasMatriculas'
import DashboardSkeleton from '../components/utils/DashboardSkeleton'
import { CursosInt } from '../../../interfaces/CursosInt'
import { useModulos } from '../../../shared/hooks/ModuloFundae'
import { useData } from '../../../shared/hooks/useData'
import { useQuerySession, EntityTypes } from '../../../shared/hooks/useQuerySession'
import { useTenantInfo } from '../../../shared/hooks/useTenantInfo'
import { EndpointTypes } from '../../../shared/utils/Types/EndpointTypes'
import { useAuthContex } from '../../../shared/context/auth.context'
import { useClient } from '../../../shared/context/client.context'
import { useNavigate } from 'react-router-dom'
import { handleUnauthorized } from '../../../shared/utils/functions/handleErrors'

export default function TabGeneral() {
    const navigate = useNavigate();
    const toast = useToast()
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });
    const { handleSetQuery: setQueryGrupos } = useQuerySession({ entity: EntityTypes.GRUPOS });
    const { handleSetQuery: setQueryMatriculas } = useQuerySession({ entity: EntityTypes.MATRICULAS });
    const { handleSetQuery: setQueryUsuarios } = useQuerySession({ entity: EntityTypes.ALUMNOS });
    const { logout } = useAuthContex();
    const client = useClient();
    const { moduloFUNDAE } = useModulos();

    const [selectedCurso, setSelectedCurso] = useState<CursosInt>({
        nombre: "Todas las matrículas",
        icono: undefined,
        descripcion: "",
        publicado: true,
        id: undefined,
    });

    const { data: stats, error, loading, errorsList, status } = useData({
        client: client,
        endpoint: EndpointTypes.STATS_ACTIVOS,
    })

    const { data: cursos } = useData({
        client: client,
        endpoint: EndpointTypes.CURSOS
    })


    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const handleSelectCurso = (curso: CursosInt) => {
        setSelectedCurso(curso);
    };

    const onCardClickGruposFundae = () => {
        setQueryGrupos({
            page: 1,
            fundae: "true",
            estado: 0
        },
            `/grupos`
        )
    };

    const onCardClickMatriculasFundae = () => {
        setQueryMatriculas({
            page: 1,
            fundae: "true",
            estado: 0
        },
            `/matriculas`
        )
    };

    const onCardClickGruposActivos = () => {
        setQueryGrupos({
            page: 1,
            estado: 0
        },
            `/grupos`
        )
    };

    const onCardClickMatriculasActivas = () => {
        setQueryMatriculas({
            page: 1,
            estado: 0
        },
            `/matriculas`
        )
    };

    const onCardClickUsuariosActivos = () => {
        setQueryUsuarios({
            page: 1,
            activo: "true",
            tab: "alumnos"
        },
            `/usuarios`
        )
    };

    const onCardClickGruposProximaSemana = () => {
        setQueryGrupos({
            page: 1,
            estado: 1,
        },
            `/grupos`
        )
    };

    const CardInfoDataFundae: any = [
        {
            onClick: onCardClickGruposFundae,
            label: "Grupos activos",
            info: stats?.gruposActivosFundae,
            tooltip: "Total de grupos activos bonificados por FUNDAE"
        },
        {
            onClick: onCardClickMatriculasFundae,
            label: "Matrículas activas",
            info: stats?.matriculasActivasFundae,
            tooltip: "Total de matriculas activas bonificadas por FUNDAE actualmente"

        },
    ]

    const CardInfoData: any = [
        {
            onClick: onCardClickGruposActivos,
            label: "Grupos activos",
            info: stats?.gruposActivosNoFundae + stats?.gruposActivosFundae,
            bg: "purewhite",
            tooltip: "Total de grupos activos actualmente"

        },
        {
            onClick: onCardClickMatriculasActivas,
            label: "Matrículas activas",
            info: stats?.matriculasActivasNoFundae + stats?.matriculasActivasFundae,
            bg: "purewhite",
            tooltip: "Total de matriculas activas actualmente"
        },
        {
            onClick: onCardClickUsuariosActivos,
            label: "Alumnos activos",
            info: stats?.usersActivos,
            bg: "purewhite",
            tooltip: "Total de alumnos activos actualmente"
        },
        {
            onClick: onCardClickGruposProximaSemana,
            label: "Grupos próxima semana",
            info: stats?.gruposProximaSemana,
            bg: "purewhite",
            tooltip: "Total de grupos que empiezan la próxima semana"
        }
    ]

    return (
        <Flex
            pt="10px"
            direction="column"
            gap="20px"
        >
            {loading ? (
                <DashboardSkeleton moduloFundae={moduloFUNDAE} />
            ) : (
                <Flex
                    gap="20px"
                    flexWrap="wrap"
                >
                    {moduloFUNDAE && CardInfoDataFundae?.map((item: any, index: number) => (
                        <CardInfoFUNDAE
                            key={index}
                            icon={index === 0 ? HiUserGroup : HiAcademicCap}
                            onClick={item?.onClick}
                            label={item?.label}
                            info={item?.info}
                            tooltip={item?.tooltip}
                        />
                    ))}
                    {CardInfoData?.map((item: any, index: number) => (
                        <CardInfo
                            key={index}
                            icon={
                                index === 0 ? HiOutlineUserGroup :
                                    index === 1 ? HiOutlineAcademicCap :
                                        index === 2 ? HiOutlineUser :
                                            index === 3 ? HiOutlineCalendar : undefined
                            }
                            onClick={item?.onClick}
                            label={item?.label}
                            info={item?.info}
                            bg={item?.bg}
                            mb={item?.mb}
                            tooltip={item?.tooltip}
                        />
                    ))}
                </Flex>
            )}
            <Flex
                gap="20px"
                w="100%"
            >
                <Flex
                    w={moduloFUNDAE ? "70%" : "100%"}
                >
                    <Matriculas
                        onSelectCurso={handleSelectCurso}
                        cursos={cursos?.data}
                        selectedCurso={selectedCurso}
                        colors={colores}
                    />
                </Flex>
                {moduloFUNDAE &&
                    <Flex
                        w="30%"
                    >
                        {<Pendiente />}
                    </Flex>
                }
            </Flex>
            <Flex
                gap="20px"
                flex="1"
            >
                <Flex
                    w="30%"
                >
                    <TopProfesores
                        colors={colores}
                    />
                </Flex>
                <Flex
                    w={"70%"}
                >
                    <UltimasMatriculas />
                </Flex>
            </Flex>
        </Flex>
    )
}
