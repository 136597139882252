import { useNavigate } from "react-router-dom";
import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { NewAlumnoModal } from "../../../components/Modals/NewAlumnoModal";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { useClient } from "../../../../../shared/context/client.context";
import { handleErrors, handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete";
import { deleteUser } from "../../../../../shared/middlewares/users.middleware";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../../shared/components/Table/Table";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { CustomButton } from "../../../../../shared/components/CustomElements/CustomButton";
import { BiPlusCircle } from "react-icons/bi";
import { AdministradoresColumns } from "./Columns/AdministradoresColumns";
import { NewAdminModal } from "../../../components/Modals/NewAdminModal";

export const AdministradoresTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.ADMINISTRADORES });
    const { user, logout } = useAuthContex();
    const client = useClient();
    const { contenido, total } = useUserPolicy();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: usuarios, loading, Refresh, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.USUARIOS,
        query: {
            ...query,
            role: "admin",
        },
    });
    const alumnoRef = useRef<any>(null);
    const [hasFilter, setHasFilters] = useState<boolean>();

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const onEventRemove = (event: any) => {
        alumnoRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onDelete = () => {
        deleteUser({ userId: alumnoRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Administrador eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!alumnoRef.current?.nombre || !alumnoRef.current?.apellidos) return defaultWord

        const keyword = alumnoRef.current?.nombre + " " + alumnoRef.current?.apellidos;

        return keyword
    }

    return (
        <Flex direction="column" gap="20px">
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.EMPRESA,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.TELEFONO,
                        type: FiltrosTypes.INPUT,
                    },
                    {
                        name: FiltrosNames.ESTADO_USUARIO,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.CORREOS_ACTIVOS,
                        type: FiltrosTypes.SELECT,
                    }
                ]}
                button={
                    <CustomButton
                        button={{
                            label: "Nuevo administrador",
                            onClick: onOpen,
                            isDisabled: contenido,
                            leftIcon: {
                                as: BiPlusCircle,
                                boxSize: "20px"
                            }
                        }}
                        tooltip={{
                            label: contenido ? "No tienes permisos para realizar esta acción" : "Crear un nuevo administrador",
                            isDisabled: false
                        }}
                    />
                }
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={usuarios?.data || []}
                columns={AdministradoresColumns({ onEventRemove, contenido })}
                isLoading={loading}
                total={usuarios?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.usuariosFiltros : EmptyMessageType.usuarios}
            />

            <NewAdminModal
                isOpen={isOpen}
                onClose={onClose}
                setRefreshTable={Refresh}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("administrador")}
            />
        </Flex>
    )
};